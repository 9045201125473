import { gql } from "@apollo/client";
import CHEF_QUERY from "./queries/chefsQuery";
import NEWS_EVENTS_QUERY from "./queries/newsEventsQuery";
import TESTIMONIAL_QUERY from "./queries/testimonialQuery";
import FAQ_QUERY from "./queries/faqQuery";
import POLICY_QUERY from "./queries/policyQuery";
import ENTITY_DETAILS_QUERY from "./queries/entityDetailsQuery";
import GALLERY_IMAGES_QUERY from "./queries/galleryImagesQuery";

const GET_CMS_DATA_QUERY = gql`
  query {
    ${FAQ_QUERY}
    ${CHEF_QUERY}
    ${NEWS_EVENTS_QUERY}
    ${TESTIMONIAL_QUERY}
    ${POLICY_QUERY}
    ${ENTITY_DETAILS_QUERY}
    ${GALLERY_IMAGES_QUERY}
  }
`;

export default GET_CMS_DATA_QUERY;
