import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { FaInfoCircle } from "react-icons/fa";
import { CTooltip, CButton } from "@coreui/react";
import MyCalendar from "./CourseCalendar";

const Course = () => {
  const { categories, allCourses } = useAuth();
  const [events, setEvents] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
  });


  const fetchCourseDurations = () => {
    const courseMappings = allCourses.map((course) => ({
      id: course._id,
      title: course.title,
      subtitle: course.subtitle,
      timeSlots: course.timeSlots,
    }));
    const courseDurations = courseMappings
      .map((course) => {
        const { id, subtitle, title, timeSlots } = course;
        const events = timeSlots.map((timeSlot) => {
          const { startDate, endDate } = timeSlot.slot;
          return {
            id,
            title,
            subtitle,
            start: new Date(startDate),
            end: new Date(endDate),
          };
        });
        return events;
      })
      .flat();
    setEvents(courseDurations);
  };

  useEffect(() => {
    fetchCourseDurations();
  }, []);

  return (
    <div className="tw-container tw-mx-auto">
      {/* <SearchBar courses={courses} categories={categories} /> */}
      <div className="tw-flex tw-flex-col">
        <h1
          className="tw-text-4xl tw-font-bold tw-text-black tw-mx-auto tw-my-10 tw-text-center tw-text-wrap"
          data-aos="zoom-in"
        >
          Courses Categories
        </h1>
        <div
          className=" tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-mx-10"
          data-aos="fade-down"
        >
          {categories.length > 0 &&
            categories.map((item) => (
              <div
                key={item._id}
                className="tw-bg-lightest tw-p-8 tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-justify-center tw-shadow-2xl"
              >
                {/* <div className=" "> */}
                <h2 className="tw-text-3xl tw-font-bold tw-mb-2 tw-text-secondary">
                  {item.title}
                </h2>
                <p className="tw-text-primary tw-text-lg tw-fonnt-bold">
                  {item.description}
                </p>

                <Link
                  to={`/courses/category/${item._id}`}
                  className="tw-font-bold hover:tw-bg-opacity-80  tw-px-4 tw-py-2 
                                tw-bg-accent tw-text-black  tw-rounded-full  tw-text-decoration-none hover:tw-bg-secondary"
                >
                  Open All
                </Link>
                {/* </div> */}
              </div>
            ))}
        </div>
      </div>
      <div className="tw-my-20 tw-flex tw-flex-col md:tw-w-1/2 tw-mx-auto">
        <h1 className="tw-text-4xl tw-font-bold tw-text-primary tw-mb-4 tw-mx-auto tw-mb-20">
          Calendar
          <CTooltip
            content="Double click on the event to view more details"
            placement="bottom"
          >
            <CButton
              style={{
                backgroundColor: "white",
                border: "none",
                padding: "0px",
              }}
            >
              <FaInfoCircle className="tw-text-2xl tw-text-primary tw-ml-2" />
            </CButton>
          </CTooltip>
        </h1>

        {events !== null && events.length > 0 && <MyCalendar events={events} />}
      </div>
    </div>
  );
};

export default Course;
