import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import Modal from "react-modal";
import { BASE_ADMIN_URL, BASE_CONTENTFUL_URL } from "../../../utils/APIURLs";
import { getCookieValue } from "../../../utils/manageCookie";
import { toast } from "react-toastify";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px", // Adjust the maximum width as needed
    paddingTop: "10px", // Add padding as needed
    backgroundColor: "var(--lighter-color)",
    minHeight: "600px", // Adjust the maximum width as needed
    zIndex: "10000 !important",
    maxWidth: "90%",
  },
  overlay: {
    zIndex: "1000",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const EditEntity = ({ entityType, editEntity, isOpenEdit, setIsOpenEdit }) => {
  const { categories, fetchAllEntities } = useAuth();
  const [existEntityDetails, setExistEntityDetails] = useState({
    title: "",
    subtitle: "",
    description: "",
    courseCategory: {
      _id: "",
      title: "",
    },
    price: "",
    totalSeats: "",
    upcomingPriority: "",
    startDate: "",
    endDate: "",
  });

  const [courseImageCMS, setCourseImageCMS] = useState("");
  const createAsset = async (file) => {
    try {
      console.log(file);
      const response = await axios.post(
        `${BASE_CONTENTFUL_URL}/createAsset`,
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const { assetId } = response.data;
      toast.success(`Image uploaded successfully`);
      setCourseImageCMS(assetId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setExistEntityDetails({
      ...existEntityDetails,
      [e.target.id]: e.target.value,
    });
  };

  const handleUpdateEntity = async (e) => {
    e.preventDefault();
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    // Update the entity
    try {
      const response = await axios.put(
        `${BASE_ADMIN_URL}/updateEntity/${editEntity._id}`,
        existEntityDetails,
        config,
      );
      toast.success(`${entityType} updated successfully`);
      // If there's a course image, upload it
      if (courseImageCMS !== "") {
        const entryData = {
          entityId: editEntity._id,
          entityTitle: existEntityDetails.title,
          entityImage: courseImageCMS,
        };
        await axios.post(`${BASE_CONTENTFUL_URL}/createEntry`, entryData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Image uploaded successfully");
      }

      setIsOpenEdit(false);
      setCourseImageCMS("");
      fetchAllEntities();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (editEntity.startDate && editEntity.endDate) {
      const formattedStartDate = editEntity.startDate.slice(0, -5);
      const formattedEndDate = editEntity.endDate.slice(0, -5);
      setExistEntityDetails({
        ...editEntity,
        startDate: formattedStartDate,
        endDate: formattedEndDate
      });
    }
  }, [editEntity]);

  return (
    <Modal
      isOpen={isOpenEdit}
      onRequestClose={() => setIsOpenEdit(false)}
      contentLabel="Edit Entity "
      style={customModalStyles}
    >
      <div className="tw-flex tw-flex-col">
        <h1 className="tw-text-primary tw-font-bold tw-text-center tw-mb-4">
          Edit {entityType}
        </h1>
        <div className="tw-w-full tw-pr-4 tw-mb-8 ">
          <form
            onSubmit={handleUpdateEntity}
            encType="multipart/form-data"
            className="tw-bg-white tw-p-6 tw-rounded tw-shadow-2xl tw-max-w-5xl tw-mx-auto"
          >
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="title"
              >
                Title
              </label>
              <input
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="title"
                type="text"
                placeholder="Title"
                value={existEntityDetails.title}
                onChange={handleChange}
              />
            </div>
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="subtitle"
              >
                Subtitle
              </label>
              <input
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="subtitle"
                type="text"
                placeholder="SubTitle"
                value={existEntityDetails.subtitle}
                onChange={handleChange}
              />
            </div>
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="description"
                type="text"
                placeholder="Description"
                value={existEntityDetails.description}
                onChange={handleChange}
              />
            </div>
            {entityType === "course" && (
              <div className="tw-mb-4">
                <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                  htmlFor="category"
                >
                  Category
                </label>
                <select
                  className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                  id="courseCategory"
                  value={existEntityDetails.courseCategory._id}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.title}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="price"
              >
                Price
              </label>
              <input
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="price"
                type="number"
                placeholder="Price"
                value={existEntityDetails.price}
                onChange={handleChange}
              />
            </div>
            <div className="tw-mb-4">
              <div className="tw-flex tw-flex-row tw-w-full">
                <div className="tw-w-1/2">
                  <label
                    className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
                    htmlFor="startDate"
                  >
                    Start Date
                  </label>
                  <input
                    className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                    id="startDate"
                    type="datetime-local"
                    value={existEntityDetails.startDate}
                    onChange={handleChange}
                  />
                </div>
                <div className="tw-w-1/2">
                  <label
                    className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
                    htmlFor="endDate"
                  >
                    End Date
                  </label>
                  <input
                    className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                    id="endDate"
                    type="datetime-local"
                    value={existEntityDetails.endDate}
                    onChange={handleChange}
                  />
                </div>
              </div>

            </div>
            <div className="tw-flex tw-flex-row tw-w-full">
              <div className="tw-mb-4 tw-w-1/2">
                <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                  htmlFor="totalSeats"
                >
                  Total Seats
                </label>
                <input
                  className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                  id="totalSeats"
                  type="number"
                  placeholder="Total Seats"
                  value={existEntityDetails.totalSeats}
                  onChange={handleChange}
                />
              </div>
              <div className="tw-mb-4 tw-w-1/2">
                <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                  htmlFor="upcomingPriority"
                >
                  Upcoming UPriority
                </label>
                <select
                  className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                  id="upcomingPriority"
                  value={existEntityDetails.upcomingPriority}
                  onChange={handleChange}
                >
                  <option value="0">Select Priority</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>


            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="img"
              >
                Image
              </label>
              <input
                className="tw-shadow  tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="courseImage"
                type="file"
                accept="image/*"
                formEncType="multipart/form-data"
                onChange={(e) => {
                  const file = e.target.files[0];
                  createAsset(file);
                }}
              />
            </div>
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-space-x-5">
              <button
                className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 tw-bg-accent hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-accent tw-text-primary tw-rounded-full tw-w-42 tw-text-xl"
                onClick={handleUpdateEntity}
              >
                Edit Course
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default EditEntity;
