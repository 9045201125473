import React from "react";
import { SocialIcon } from "react-social-icons";
import NewsLetter from "./NewsLetter";

const Footer = () => {
  return (
    <footer className="tw-bg-accent tw-py-8 md:tw-pb-8 tw-pb-32">
      <div className="tw-container tw-mx-auto tw-flex md:tw-px-32 tw-flex-col md:tw-flex-row tw-justify-between tw-items-center md:tw-order-1 tw-order-last">
        <div className="tw-flex tw-items-center tw-space-x-8 tw-text-xl tw-mb-8">
           <a href="#" className="tw-text-white hover:text-primary">
            {/* <FaInstagram /> */}
            <SocialIcon network="instagram" url="https://www.instagram.com/100foldsacademy/" style={{ height: 40, width: 40 }} />
          </a>
          <a href="#" className="tw-text-white tw-hover:text-gray-300">
            {/* <FaLinkedin /> */}
            <SocialIcon network="linkedin" url="https://www.linkedin.com/company/100-folds-culinary-guild/" style={{ height: 40, width: 40 }} />
          </a>
          <a href="#" className="tw-text-white tw-hover:text-gray-300">
            <SocialIcon network="facebook" url="https://www.facebook.com/profile.php?id=61559125152281" style={{ height: 40, width: 40 }} />
          </a>
        </div>
        <div className="tw-flex tw-flex-col tw-items-center">
          <div className="tw-text-primary tw-text-lg md:tw-mt-0 tw-mb-5">Copyright © DPAA Academy LLP. All rights reserved.</div>
          <div className="tw-text-primary tw-text-lg md:tw-mt-0 tw-mb-5">Website powered by <a href="https://www.cyfrone.com" className="tw-text-blue-500 tw-hover:text-blue-700">CyfrOne</a></div>
        </div>
        <NewsLetter className="md:tw-order-last tw-order-1 tw-mt-10" />
      </div>
    </footer>
  );
};

export default Footer;
