import React, { useState } from "react";
import axios from "axios";
import { BASE_USER_URL } from "../../utils/APIURLs";
import { IoMdEyeOff } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { token } = useParams();
  const [formData, setFormData] = useState({
    token: token,
    password: "",
    confirmPassword: "",
  });
  const [icon, setIcon] = useState("eye-off");
  const [type, setType] = useState("password");

  const [formErrors, setFormErrors] = useState({});

  const handleTogglePassword = () => {
    if (type === "password") {
      setType("text");
      setIcon("eye");
    } else {
      setType("password");
      setIcon("eye-off");
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.password) {
      error.password = "Password is required";
    }

    if (values.password !== values.confirmPassword) {
      error.confirmPassword = "Passwords do not match";
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validateForm(formData));

    try {
      const response = await axios.put(
        `${BASE_USER_URL}/resetPassword`,
        formData,
      );
      toast.success("Password reset successfully");
      setFormData({
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      const err = error.response.data;
      toast.error(err.message);
    }
  };

  return (
    <div className="tw-container tw-mx-auto tw-mt-32 tw-items-center">
      <h2 className="tw-text-5xl tw-font-bold tw-mb-4 tw-text-center">
        Reset Password
      </h2>
      <div className="tw-flex tw-flex-col tw-w-1/2 tw-mx-auto">
        <form onSubmit={handleSubmit}>
          <div className="tw-mb-4">
            <label
              htmlFor="password"
              className="tw-block tw-text-md tw-font-medium tw-text-primary"
            >
              Password *
            </label>
            <div className="tw-relative">
              <input
                type={type}
                id="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
              />
              <span
                onClick={handleTogglePassword}
                className="tw-absolute tw-right-2 tw-top-2 tw-text-primary tw-cursor-pointer"
              >
                {icon === "eye-off" ? (
                  <IoMdEyeOff size={30} />
                ) : (
                  <IoMdEye size={30} />
                )}
              </span>
            </div>
            {formErrors.password && (
              <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                {formErrors.password}
              </p>
            )}
          </div>
          <div className="tw-mb-4">
            <label
              htmlFor="confirmPassword"
              className="tw-block tw-text-md tw-font-medium tw-text-primary"
            >
              Confirm Password *
            </label>
            <div className="tw-relative">
              <input
                type={type}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
              />
              <span
                onClick={handleTogglePassword}
                className="tw-absolute tw-right-2 tw-top-2 tw-text-primary tw-cursor-pointer"
              >
                {icon === "eye-off" ? (
                  <IoMdEyeOff size={30} />
                ) : (
                  <IoMdEye size={30} />
                )}
              </span>
            </div>
            {formErrors.confirmPassword && (
              <p className="tw-text-red-500 tw-text-xs mt-1">
                {formErrors.confirmPassword}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="tw-rounded-full tw-flex tw-text-white tw-bg-primary tw-border tw-border-4 tw-border-double tw-p-4  tw-w-1/3 tw-mx-auto hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-primary tw-justify-center "
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
