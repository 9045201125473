import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { BASE_USER_URL } from "../../utils/APIURLs";
import { toast } from "react-toastify";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "300px", // Adjust the maximum width as needed
    padding: "20px", // Add padding as needed
    backgroundColor: "#fff",
    minHeight: "400px", // Adjust the maximum width as needed
    zIndex: "10000",
    maxWidth: "400px",
  },
  overlay: {
    zIndex: "1000",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const ForgotPassword = ({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      error.email = "Email is required";
    } else if (!regex.test(values.email)) {
      error.email = "Email address is invalid";
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setFormErrors(validateForm(formData));

    try {
      const response = await axios.post(
        `${BASE_USER_URL}/send-reset-password`,
        formData,
      );
      setIsOpen(false);
      toast.success("Reset link sent successfully on Email");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Forgot Password Modal"
      style={customModalStyles}
    >
      <h2 className="tw-text-primary tw-font-bold tw-mb-4 tw-text-center">
        Forgot Password
      </h2>
      <hr />
      <h5 className="tw-text-secondary tw-mb-4 tw-text-center">
        Enter your email address and we will send you a link to reset your
        password.
      </h5>
      <form onSubmit={handleSubmit}>
        <div className="tw-mb-4">
          <label
            htmlFor="email"
            className="tw-block tw-text-md tw-font-medium tw-text-primary"
          >
            Email *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
          />
          {formErrors.email && (
            <p className="tw-text-red-500 tw-text-xs tw-mt-1">
              {formErrors.email}
            </p>
          )}
        </div>
        <div className="tw-flex tw-items-center">
        <button
          type="submit"
          className="tw-bg-accent tw-border tw-border-4 tw-border-double tw-border-primary hover:tw-bg-transparent tw-text-black tw-px-4 tw-py-2 tw-rounded-full tw-mx-auto"
        >
          Send Reset Link
        </button>
        </div>
      </form>
    </Modal>
  );
};

export default ForgotPassword;
