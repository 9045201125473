import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_ADMIN_URL } from "../../utils/APIURLs";

function AdminRegister() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (values.email && !regex.test(values.email)) {
      error.email = "Email address is invalid";
    }
    if (!values.name) {
      error.name = "Name is required";
    }
    if (!values.password) {
      error.password = "Password is required";
    } else if (values.password.length < 8) {
      error.password = "Password must be at least 8 characters";
    }
    if (!values.confirmPassword) {
      error.confirmPassword = "Confirm Password is required";
    } else if (values.password !== values.confirmPassword) {
      error.confirmPassword = "Passwords must match";
    }
    return error;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validateForm(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      axios
        .post(`${BASE_ADMIN_URL}/register`, formData)
        .then((response) => {
          navigate("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [formErrors]);

  return (
    <>
      <div className="tw-container tw-mx-auto tw-mt-24">
        <div className="tw-flex tw-flex-col tw-items-center tw-mt-12">
          <h1 className="tw-text-5xl tw-font-bold tw-mb-4 tw-text-primary ">
            Create a New Account as an Admin
          </h1>
          <p className="tw-text-gray-600 tw-mb-4">
            Please fill out the form below to create a new account. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
          </p>
          <p className="tw-text-gray-600 tw-mb-4">
            All fields marked with a (*) are required.
          </p>
          <h6 className="tw-text-xl tw-mb-4 tw-text-gray-600">
            Already have an account?{" "}
            <a href="/login" className="tw-text-accent tw-ml-2">
              Login
            </a>
          </h6>
        </div>
        <div className="tw-flex tw-flex-col">
          <div className="tw-w-full tw-pr-4 tw-mb-8 ">
            <form
              onSubmit={handleSubmit}
              className="tw-bg-white tw-p-6 tw-rounded tw-shadow-2xl tw-max-w-5xl tw-mx-auto"
            >
              {/* Name */}
              <div className="tw-mb-4">
                <label
                  htmlFor="firstName"
                  className="tw-block tw-text-md tw-font-medium tw-text-gray-600"
                >
                  Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.name && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.name}
                  </p>
                )}
              </div>

              {/* Email */}
              <div className="tw-mb-4">
                <label
                  htmlFor="email"
                  className="tw-block tw-text-md tw-font-medium tw-text-gray-600"
                >
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.email && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.email}
                  </p>
                )}
              </div>

              {/* Password */}
              <div className="tw-mb-4">
                <label
                  htmlFor="password"
                  className="tw-block tw-text-md tw-font-medium tw-text-gray-600"
                >
                  Password *
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.password && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.password}
                  </p>
                )}
              </div>

              {/* Confirm Password */}
              <div className="tw-mb-4">
                <label
                  htmlFor="confirmPassword"
                  className="tw-block tw-text-md tw-font-medium tw-text-gray-600"
                >
                  Confirm Password *
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.confirmPassword && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.confirmPassword}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="tw-rounded-full tw-text-white tw-bg-primary tw-border tw-border-4 tw-border-double tw-p-4 tw-w-full tw-mx-auto hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-primary"
              >
                Register
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminRegister;
