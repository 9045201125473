import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { BASE_ADMIN_URL, BASE_ENTITY_URL } from "../../../utils/APIURLs";
import { getCookieValue } from "../../../utils/manageCookie";
import { toast } from "react-toastify";
import { formattedDate, getDefaultDateTime } from "../../../utils/helpfulFunctions";

const ScheduleEntity = () => {
  const { categories, fetchAllEntities } = useAuth();
  const [entities, setEntities] = useState([]);
  const [entityType, setEntityType] = useState("");
  const [existEntityDetails, setExistEntityDetails] = useState({
    itemId: "",
    startDate: getDefaultDateTime("09:00"), // Set default start date to 9am
    endDate: getDefaultDateTime("17:00"), // Set default end date to 5pm
    totalSeats: "",
  });

  const [filteredEntities, setFilteredEntities] = useState([entities]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (selectedCategory) {
      const filtered = entities.filter(
        (entity) => entity.courseCategory === selectedCategory,
      );
      setFilteredEntities(filtered);
    } else {
      setFilteredEntities(entities);
    }
  }, [selectedCategory]);

  const handleScheduleChange = (e) => {
    setExistEntityDetails({
      ...existEntityDetails,
      [e.target.id]: e.target.value,
    });
  };

  const handleScheduleEntity = async (e) => {
    e.preventDefault();
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(`${BASE_ADMIN_URL}/scheduleEntity`, existEntityDetails, config)
      .then((res) => {
        toast.success(`${entityType} scheduled successfully`);
        setExistEntityDetails({
          itemId: "",
          startDate: "",
          endDate: "",
          totalSeats: "",
        });
        fetchAllEntities();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to schedule course");
      });
  };

  const getAllEntities = async (itemType) => {
    const res = await axios.get(`${BASE_ENTITY_URL}`, { params: { itemType } });
    setEntities(res.data);
    setFilteredEntities(res.data);
  };

  useEffect(() => {
    if (entityType) {
      getAllEntities(entityType);
    }
  }, [entityType]);

  return (
    <div className=" tw-conatiner tw-mx-auto tw-pt-32 tw-flex tw-flex-col">
      <div className="tw-w-full tw-pr-4 tw-mb-8 ">
        {/* choose entity type from the dropdown */}
        <h1 className="tw-text-3xl tw-text-center tw-mb-4 tw-font-bold tw-text-primary">
          Schedule Course or Demo Class
        </h1>
        <div className="tw-bg-white tw-p-8 tw-mb-4 md:tw-w-1/3 tw-w-3/4 tw-mx-auto tw-shadow-2xl">
          <label
            className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
            htmlFor="entity"
          >
            Select Entity Type
          </label>
          <select
            className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
            id="entity"
            value={entityType}
            onChange={(e) => setEntityType(e.target.value)}
          >
            <option value="">Select Entity Type</option>
            <option value="course">Course</option>
            <option value="demoClass">Demo Class</option>
          </select>
        </div>
        {entityType === "course" && (
          <div className="tw-bg-white tw-p-8 tw-mb-4 tw-w-3/4 md:tw-w-1/3 tw-mx-auto tw-shadow-2xl">
            <label
              className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
              htmlFor="entity"
            >
              Select {entityType} category
            </label>
            <select
              className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
              id="category"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
        )}
        {entityType !== "" && (
          <form
            onSubmit={handleScheduleEntity}
            className="tw-bg-white tw-p-6 tw-rounded tw-shadow-2xl tw-max-w-5xl tw-mx-auto"
          >
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="itemId"
              >
                Choose the {entityType}
              </label>
              <select
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="itemId"
                value={existEntityDetails.itemId}
                onChange={handleScheduleChange}
              >
                <option value="">Select {entityType}</option>
                {filteredEntities.map((entity) => (
                  <option key={entity._id} value={entity._id}>
                    {entity.title}  {entity.subtitle}
                  </option>
                ))}
              </select>
            </div>
            <div className="tw-mb-4">
              <div className="tw-flex tw-flex-row tw-w-full">
                <div className="tw-w-1/2">
                  <label
                    className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
                    htmlFor="startDate"
                  >
                    Start Date
                  </label>
                  <input
                    className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                    id="startDate"
                    type="datetime-local"
                    value={existEntityDetails.startDate}
                    onChange={handleScheduleChange}
                  />
                </div>
                <div className="tw-w-1/2">
                  <label
                    className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
                    htmlFor="endDate"
                  >
                    End Date
                  </label>
                  <input
                    className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                    id="endDate"
                    type="datetime-local"
                    value={existEntityDetails.endDate}
                    onChange={handleScheduleChange}
                  />
                </div>
              </div>
              <div className="tw-mt-4">
                <div className="tw-w-full">
                  <label
                    className="tw-block tw-text-primary tw-text-sm tw-font-bold tw-mb-2"
                    htmlFor="totalSeats"
                  >
                    Total Seats
                  </label>
                  <input
                    className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-primary tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                    id="totalSeats"
                    type="number"
                    value={existEntityDetails.totalSeats}
                    onChange={handleScheduleChange}
                  />
                </div>
              </div>
            </div>

            <button
              className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 tw-bg-accent hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-accent tw-text-primary tw-rounded-full tw-w-42 tw-text-xl"
              onClick={handleScheduleEntity}
            >
              Schedule Course
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ScheduleEntity;
