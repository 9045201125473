import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useAuth } from "../../AuthContext";
import { toast } from "react-toastify";
import {
  BASE_ENTITY_URL,
  BASE_ADMIN_URL,
  BASE_USER_URL,
  BASE_PAYMENT_URL,
} from "../../utils/APIURLs";
import { getCookieValue } from "../../utils/manageCookie";
import { formattedDate } from "../../utils/helpfulFunctions";

const CourseRegister = () => {
  const { categories, fetchAllEntities } = useAuth();
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [allSlotsOfCourse, setAllSlotsOfCourse] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [userData, setUserData] = useState({
    email: "",
    phone: "",
    paymentMode: "",
    remarks: "",
    paymentAmount: "",
  });

  const validateForm = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email is invalid";
    } else {
      errors.email = "";
    }
    if (!values.phone) {
      errors.phone = "Phone is required";
    }
    setFormErrors(errors);
  };

  useEffect(() => {
    axios
      .get(`${BASE_ENTITY_URL}`)
      .then((res) => {
        const { courses } = res.data;
        setCourses(courses);
        setFilteredCourses(courses);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handlePhone = (value) => {
    setUserData((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const handleSendConfirmation = (items, email) => {
    try {
      const res = axios.post(`${BASE_USER_URL}/sendCourseRegistrationEmail`, {
        items,
        email,
      });
      if (res.status === 200) {
        toast.success("Confirmation sent successfully");
      }
    } catch (error) {
      toast.error("Failed to send confirmation");
    }
  };

  const addUserToCourse = async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.post(`${BASE_ADMIN_URL}/addUserToCourse`, {
        courseId: selectedCourse,
        slotId: selectedSlot,
        email: userData.email,
        paymentMode: userData.paymentMode,
        paymentAmount: userData.paymentAmount,
        remarks: userData.remarks,
      }, config);
      if (res.status === 200) {
        toast.success("User added to course successfully");
      }
    }
    catch (error) {
      toast.error("Failed to add user to course");
    }

  };

  const handleRegister = async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    const buyItems = [];
    buyItems.push({ _id: selectedCourse, slotId: selectedSlot, itemType: "course", amount: userData.paymentAmount });
    try {
      const res = await axios.post(
        `${BASE_USER_URL}/buyEntity`,
        { items: buyItems , email: userData.email},
        config,
      );

      if (res.status === 200) {
        addUserToCourse();
        handleSendConfirmation(buyItems, userData.email);
      }
    } catch (error) {
      const errorMessage =
        error.response.data.message || "Failed to buy course";
      if (errorMessage.includes("Course already purchased")) {
        toast.error("Course is already purchased");
      } else if (errorMessage.includes("Course is full")) {
        toast.warning("Sorry, this course is already full");
      } else if (errorMessage.includes("Course not found")) {
        toast.error("The requested course was not found");
      } else if (errorMessage.includes("User not found")) {
        toast.error("User not found. Please log in again");
      } else {
        toast.error(errorMessage);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm(userData);
    if (Object.keys(formErrors).length === 0) {
      handleRegister();
    }
  };
  
  useEffect(() => {
    console.log(selectedSlot);
  }, [selectedSlot]);

  useEffect(() => {
    if (selectedCategory) {
      const filteredCourses = courses.filter(
        (course) => course.courseCategory.title  === selectedCategory,
      );
      setFilteredCourses(filteredCourses);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCourse) {
      const course = courses.find((course) => course._id === selectedCourse);
      setAllSlotsOfCourse(course.timeSlots);
      if (course.courseCategory.title !== "Our Services") {
        setUserData((prevState) => ({
          ...prevState,
          paymentAmount: course.price,
        }));
      }
    }
  }, [selectedCourse]);

  return (
    <div className="tw-container tw-mx-auto tw-mt-8">
      <h1 className="tw-text-primary tw-font-bold tw-mb-8 tw-text-center">
        Register for a Course
      </h1>
      <div className="tw-flex tw-justify-center tw-items-center tw-flex-col md:tw-flex-row tw-mx-auto md:tw-w-1/2 tw-w-full">
        {/* dropdown field for itemType  */}
        <div className="tw-flex tw-flex-col tw-w-1/2">
          <label
            htmlFor="category"
            className="tw-block tw-text-md tw-font-medium tw-text-primary"
          >
            Select Course Category
          </label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="tw-border tw-p-2 tw-mr-2 tw-w-full tw-rounded-md"
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category.title}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
        <div className="tw-flex tw-flex-col tw-w-1/2 tw-mt-4 md:tw-mt-0">
          <label
            htmlFor="course"
            className="tw-block tw-text-md tw-font-medium tw-text-primary"
          >
            Select Course
          </label>
          <select
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
            className="tw-border tw-p-2 md:tw-ml-2 tw-w-full tw-rounded-md"
          >
            <option value="">Select Course</option>
            {filteredCourses.map((course) => (
              <option key={course._id} value={course._id}>
                {course.title} {course.subtitle}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedCourse && selectedCategory && selectedCategory === "Our Courses" && (
        // slot selection
        <div className="tw-flex tw-justify-center tw-items-center tw-flex-col md:tw-flex-row tw-mx-auto md:tw-w-1/2 tw-w-full tw-mt-4">
          <div className="tw-flex tw-flex-col tw-w-1/2">
            <label
              htmlFor="slot"
              className="tw-block tw-text-md tw-font-medium tw-text-primary"
            >
              Select Slot
            </label>
            <select
              value={selectedSlot}
              onChange={(e) => setSelectedSlot(e.target.value)}
              className="tw-border tw-p-2 tw-mr-2 tw-w-full tw-rounded-md"
            >
              <option value="">Select Slot</option>
              {allSlotsOfCourse.map((item) => (
                <option key={item._id} value={item._id}>
                  {formattedDate(item.slot.startDate)} -{" "}
                  {formattedDate(item.slot.endDate)}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {/* {selectedCourse && selectedCategory && (
        <p className="tw-text-primary tw-font-bold">{selectedSlot.remainingSeats}</p>
      )} */}
      {selectedCourse  && selectedCategory && (
        <div className="tw-flex tw-flex-col">
          <div className="tw-w-3/4 md:tw-w-1/3 tw-mx-auto tw-pr-4 tw-mb-8 ">
            <h2 className="tw-text-2xl tw-font-bold tw-my-4 tw-text-center">
              Student Details
            </h2>

            <form
              onSubmit={handleSubmit}
              className="tw-bg-white tw-p-6 tw-rounded tw-shadow-2xl tw-max-w-5xl tw-mx-auto"
            >
              {/* Email */}
              <div className="tw-mb-4">
                <label
                  htmlFor="email"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={userData.email}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.email && (
                  <span className="tw-text-red-500">{formErrors.email}</span>
                )}
              </div>

              <div className="tw-flex tw-flex-row tw-w-full tw-space-x-2">
                {/* Phone */}
                <div className="tw-mb-1 tw-w-full">
                  <label
                    htmlFor="phone"
                    className="tw-block tw-text-md tw-font-medium tw-text-primary"
                  >
                    Phone
                  </label>
                  <PhoneInput
                    id="phone"
                    name="phone"
                    defaultCountry="IN"
                    placeholder="Phone"
                    value={userData.phone}
                    onChange={(phone) => handlePhone(phone)}
                    className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                  />
                  {formErrors.phone && (
                    <span className="tw-text-red-500">{formErrors.phone}</span>
                  )}
                </div>
              </div>

              <div className="tw-mb-4">
                <label
                  htmlFor="paymentMode"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  Payment Mode
                </label>
                <select
                  id="paymentMode"
                  name="paymentMode"
                  value={userData.paymentMode}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                >
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Debit-card">Debit Card</option>
                  <option value="Credit-card">Credit Card</option>
                  <option value="netbaking-RTGS">Netbanking - RTGS</option>
                  <option value="Netbaking-NEFT">Netbanking - NEFT</option>
                  <option value="Netbanking-IMPS">Netbanking - IMPS</option>
                  <option value="UPI">UPI</option>
                </select>
              </div>


              <div className="tw-mb-4">
                <label
                  htmlFor="remarks"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  Remarks
                </label>
                <input
                  type="text"
                  id="remarks"
                  name="remarks"
                  placeholder="Remarks"
                  value={userData.remarks}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                
              </div>
              <div className="tw-mb-4">
                <label
                  htmlFor="paymentAmount"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  Amount Paid
                </label>
                <input
                  type="number"
                  id="paymentAmount"
                  name="paymentAmount"
                  value={userData.paymentAmount}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                ></input>
              </div>
              <button
                type="submit"
                className="tw-rounded-full tw-text-white tw-bg-primary tw-border tw-border-4 tw-border-double tw-p-4 tw-w-full tw-mx-auto hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-primary"
                onClick={handleSubmit}
              >
                Initiate Payment
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseRegister;
