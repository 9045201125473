const GALLERY_IMAGES_QUERY = `
    galleryImagesCollection {
        items {
            course 
            img{
                fileName
                url
            }
            uploadTime
            height
            width
        }
    }
`;

export default GALLERY_IMAGES_QUERY;
