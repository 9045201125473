import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaArrowRight, FaPhoneAlt, FaRegIdCard } from "react-icons/fa";
import "./styles/StickyButtons.css";

const StickyButtons = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [hideButtonText, setHideButtonText] = useState("Hide");
  const Navigate = useNavigate();

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
    setHideButtonText(isHidden ? "Hide" : "Show");
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 768 ? (
        <div className="sticky-buttons">
          <button
            className={`sticky-button ${hoveredButton === "hide" ? "expanded" : ""} ${isHidden ? "hide" : ""}`}
            onClick={toggleVisibility}
            onMouseEnter={() => setHoveredButton("hide")}
            onMouseLeave={() => setHoveredButton(null)}
          >
            <FaArrowRight className={`hide-icon ${isHidden ? "rotate" : ""}`} />
            <span className="button-text">{hideButtonText}</span>
          </button>

          {/* Sticky buttons */}
          {!isHidden && (
            <>
              <Link to="/contact-us">
                <button
                  className={`sticky-button ${hoveredButton === "contact" ? "expanded" : ""}`}
                  onMouseEnter={() => setHoveredButton("contact")}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  <FaPhoneAlt className="icon" />
                  <span className="button-text">Contact Us</span>
                </button>
              </Link>
              <Link to="/courses">
                <button
                  className={`sticky-button ${hoveredButton === "courses" ? "expanded" : ""}`}
                  onMouseEnter={() => setHoveredButton("courses")}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  <FaRegIdCard className="icon" />
                  <span className="button-text">Enroll</span>
                </button>
              </Link>
            </>
          )}
        </div>
      ) :
        (
          <div className="sticky-buttons">
            <div
              className="sticky-button"
              onClick={() => Navigate("/contact-us")}
            >
              <FaPhoneAlt className="icon" />
              <span className="button-text">Contact Us</span>
            </div>
              <div
              className="sticky-button"
              onClick={()=> Navigate("/courses")}
              >
                <FaRegIdCard className="icon" />
                <span className="button-text">Enroll</span>
              </div>
          </div>
        )
      }

    </>
  );
};

export default StickyButtons;
