const TESTIMONIAL_QUERY = `
    testimonialCollection{
      items {
        id
        name
        quote
        img {
          fileName
          url
        }
        designation
        course
      }
    }
`;

export default TESTIMONIAL_QUERY;
