import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { BASE_PAYMENT_URL, BASE_USER_URL } from "../../utils/APIURLs";
import { getCookieValue } from "../../utils/manageCookie";
import axios from "axios";
import { toast } from "react-toastify";

const PaymentStatus = () => {
    const location = useLocation();
    const [status, setStatus] = useState(null);
    const { fetchAllEntities, fetchBoughtItems, cartSize, updateCartSize, getCartItems, userRole } = useAuth();

    const handleSendConfirmation = async (items, email) => {
        try {
            const res = await axios.post(`${BASE_USER_URL}/sendCourseRegistrationEmail`, {
                items,
                email,
            });
            if (res.status === 200) {
                toast.success("Confirmation sent successfully");
            }
        } catch (error) {
            toast.error("Failed to send confirmation");
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const transactionId = searchParams.get("transaction_id");
        const authToken = getCookieValue("authToken");
        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };

        const getStatus = async () => {
            try {
                const res = await axios.post(`${BASE_PAYMENT_URL}/status`, {
                    transactionId: transactionId,
                }, config);
                setStatus(res.data.code);
                localStorage.setItem(`paymentStatus_${transactionId}`, res.data.code);
            }
            catch (error) {
                console.error(error);
            }
        };

        getStatus();
        
    }, [location.search]);

    const addUserToCourseOnSuccess = async (status, email, transactionId) => {
        const authToken = getCookieValue("authToken");
        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };
        const items = JSON.parse(localStorage.getItem("buyItems"));
        try {
            const res = await axios.post(`${BASE_PAYMENT_URL}/addUserToCourse`, {
                items,
                email,
                remarks: "Payment successful",
                status,
                transactionId,
            }, config);
            if (res.status === 200) {
                const msg = res.data.message;
                if (msg === "Payment successful") {
                    handleSendConfirmation(items, email);
                    toast.success("You are added to course successfully");
                }
                fetchAllEntities();
                fetchBoughtItems();
                if (userRole === "student") {
                    updateCartSize(cartSize - items.length);
                    getCartItems();
                }
            }
        }
        catch (error) {
            toast.error("Failed to add user to course");
        }

    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const transactionId = searchParams.get("transaction_id");
        const amount = searchParams.get("amount");
        const email = searchParams.get("email");
        const status = localStorage.getItem(`paymentStatus_${transactionId}`);
        if (status) {
            addUserToCourseOnSuccess(status, email, transactionId);
        }
        localStorage.removeItem(`paymentStatus_${transactionId}`);
    }, [status]);


    return (
        <div className="tw-container tw-mx-auto tw-mt-48  tw-text-center ">
            {status === "PAYMENT_SUCCESS" && (
                <div>
                    <h1 className="tw-text-secondary tw-text-5xl">Payment Successful</h1>
                    <p className="tw-text-primary tw-font-bold tw-text-2xl">Your payment was successful. You can now access the course.</p>
                    {userRole === "student" && (
                        <button className="tw-bg-primary tw-text-white tw-text-xl tw-py-2 tw-px-4 tw-mt-4 tw-rounded-md" onClick={() => window.location.href = "/courses"}>Go to Courses</button>
                    )}
                    {userRole === "admin" || userRole === "superAdmin" && (
                        <button className="tw-bg-primary tw-text-white tw-text-xl tw-py-2 tw-px-4 tw-mt-4 tw-rounded-md" onClick={() => window.location.href = "/admin/dashboard"}>Go to Admin Panel</button>
                    )}
                </div>

            )}
            {status === "PAYMENT_ERROR" && (
                <div>
                    <h1 className="tw-text-primary">Payment Failed</h1>
                    <p>Your payment was not successful. Please try again.</p>
                </div>
            )}
            {status === "PAYMENT_PENDING" && (
                <div>
                    <h1 className="tw-text-primary">Payment Pending</h1>
                    <p>Your payment is pending. Please wait for the payment to be processed.</p>
                </div>
            )}
            {status !== "PAYMENT_SUCCESS" && status !== "PAYMENT_ERROR" && status !== "PAYMENT_PENDING" && (
                <div>
                    <h1 className="tw-text-primary">Payment Status</h1>
                    <p>Fetching payment status...</p>
                </div>
            )}
        </div>
    );
};

export default PaymentStatus;
