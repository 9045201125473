import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import { BASE_USER_URL } from "../../utils/APIURLs";
import { useAuth } from "../../AuthContext";

function Register() {
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    email: "",
    phone: "",
    dob: new Date(),
    verificationCode: "",
    password: "",
    confirmPassword: "",
    newsletterSubscribed: false,
    newsletterPreference: [],
  });
  const { userRole } = useAuth();
  const [generatedCode, setGeneratedCode] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isClickVerify, setIsClickVerify] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log(name, value, type, checked);
    if (type === "checkbox" && name === "newsletterPreference") {
      const updatedPreferences = checked
        ? [...formData.newsletterPreference, value]
        : formData.newsletterPreference.filter(
            (preference) => preference !== value,
          );
      setFormData((prevState) => ({
        ...prevState,
        newsletterPreference: updatedPreferences,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value.trim(),
      }));
    }
  };

  const handlePhone = (value) => {
    console.log(value);
    setFormData((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (values.email && !regex.test(values.email)) {
      error.email = "Email address is invalid";
    }
    if (!values.title) {
      error.title = "Title is required";
    }
    if (!values.firstName) {
      error.firstName = "First Name is required";
    }
    if (!values.lastName) {
      error.lastName = "Last Name is required";
    }
    if (!values.password) {
      error.password = "Password is required";
    } else if (values.password.length < 8) {
      error.password = "Password must be at least 8 characters";
    }
    if (!values.confirmPassword) {
      error.confirmPassword = "Confirm Password is required";
    } else if (values.password !== values.confirmPassword) {
      error.confirmPassword = "Passwords must match";
    }
    if (!values.dob) {
      error.dob = "Date of Birth is required";
    }
    return error;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isVerified) {
      toast.error("Please verify your email before registering.");
      return;
    }
    setFormErrors(validateForm(formData));
    setIsSubmit(true);
  };

  const handleSendVerify = (event) => {
    event.preventDefault();
    if (!formData.email) {
      setFormErrors((prevState) => ({
        ...prevState,
        email: "Email is required",
      }));
      return;
    }
    axios
      .post(`${BASE_USER_URL}/send-verification-code`, formData)
      .then((response) => {
        let code = response.data.code;
        setGeneratedCode(code.toString());
        toast.success("Verification code sent successfully");
        setIsClickVerify(true);
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        setFormErrors((prevState) => ({
          ...prevState,
          email: errorMessage,
        }));
      });
  };

  const handleVerify = (event) => {
    event.preventDefault();
    console.log(typeof formData.verificationCode, typeof generatedCode);
    if (formData.verificationCode === generatedCode) {
      setIsVerified(true);
      toast.success("Email verified successfully");
    } else {
      toast.error("Verification code is incorrect");
      console.log("not verified");
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formData);
      axios
        .post(`${BASE_USER_URL}/register`, formData)
        .then((response) => {
          console.log(response);
          toast.success("User registered successfully");
          setIsVerified(false);
          if (userRole === "admin") {
            navigate("/admin/dashboard");
          } else {
            navigate("/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [formErrors]);

  return (
    <>
      <div className="tw-container tw-mx-auto ">
        <div className="tw-flex tw-flex-col tw-items-center tw-mt-12 tw-p-2">
          <h1 className="md:tw-text-5xl tw-text-3xl tw-font-bold tw-mb-4 tw-text-primary ">
            Create a New Account
          </h1>
          <p className="tw-text-primary tw-font-bold tw-mb-4">
            Please fill out the form below to create a new account.
          </p>
          <p className="tw-text-secondary tw-mb-4">
            All fields marked with a (*) are required.
          </p>
          {userRole !== "admin" && (
            <h6 className="tw-text-2xl tw-mb-4 tw-text-primary">
              Already have an account?{" "}
              <a href="/login" className="tw-text-accent tw-ml-2">
                Login
              </a>
            </h6>
          )}
        </div>
        <div className="tw-flex tw-flex-col tw-mx-auto">
          <div className="tw-w-full tw-px-2 tw-mb-8">
            <form
              onSubmit={handleSubmit}
              className="tw-bg-lightest tw-p-6 tw-rounded tw-shadow-2xl  tw-max-w-3xl tw-mx-auto"
            >
              {/* Dropdown for Title */}
              <div className="tw-mb-4">
                <label
                  htmlFor="title"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  Title *
                </label>
                <select
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                >
                  <option value="">Select Title</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Miss">Miss</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Mr">Mr</option>
                  <option value="Other">Other</option>
                </select>
                {formErrors.title && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.title}
                  </p>
                )}
              </div>

              {/* First Name */}
              <div className="tw-mb-4">
                <label
                  htmlFor="firstName"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.firstName && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.firstName}
                  </p>
                )}
              </div>

              {/* Last Name */}
              <div className="tw-mb-4">
                <label
                  htmlFor="lastName"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.lastName && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.lastName}
                  </p>
                )}
              </div>

              {/* Email */}
              <div className="tw-mb-4">
                <label
                  htmlFor="email"
                  className="tw-block tw-text-md tw-font-medium tw-text-primary"
                >
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                />
                {formErrors.email && (
                  <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                    {formErrors.email}
                  </p>
                )}
              </div>
              {!isVerified && !isClickVerify && (
                <>
                  <button
                    className="tw-bg-primary tw-mb-2 tw-text-white tw-py-1 tw-px-2 tw-rounded-md tw-text-sm"
                    onClick={handleSendVerify}
                  >
                    Verify Email
                  </button>
                  <p className="tw-text-green-600">
                    {" "}
                    A one-time verification code will be sent to your email.
                  </p>
                </>
              )}
              {isClickVerify && !isVerified && formData.email != "" && (
                <>
                  <div className="tw-flex tw-flex-col tw-space-x-4 tw-justify-center">
                    <div className="tw-flex tw-flex-col tw-mb-2">
                      <label
                        htmlFor="verificationCode"
                        className="tw-block tw-text-md tw-font-medium tw-text-primary"
                      >
                        Verification Code *
                      </label>
                      <input
                        type="text"
                        id="verificationCode"
                        name="verificationCode"
                        placeholder="Verification Code"
                        value={formData.verificationCode}
                        onChange={handleChange}
                        className="tw-mt-1 tw-p-2 tw-border tw-rounded"
                      />
                    </div>
                  </div>
                  <button
                    className=" tw-bg-primary tw-mb-2 tw-text-white tw-py-1 tw-px-2 tw-rounded-md tw-text-sm"
                    onClick={handleVerify}
                  >
                    Verify
                  </button>
                </>
              )}
              {isVerified && (
                <>
                  <div className="tw-flex tw-flex-row tw-w-full tw-space-x-2">
                    {/* Phone */}
                    <div className="tw-mb-1 tw-w-1/2">
                      <label
                        htmlFor="phone"
                        className="tw-block tw-text-md tw-font-medium tw-text-primary"
                      >
                        Phone
                      </label>
                      <PhoneInput
                        id="phone"
                        name="phone"
                        defaultCountry="IN"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={(phone) => handlePhone(phone)}
                        className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                      />

                      {formErrors.phone && (
                        <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                          {formErrors.phone}
                        </p>
                      )}
                    </div>

                    {/* Date of Birth */}
                    <div className="tw-mb-4 tw-w-1/2">
                      <label
                        htmlFor="dob"
                        className="tw-block  tw-text-primary"
                      >
                        Date of Birth *
                      </label>
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        placeholder="Date of Birth"
                        value={formData.dob}
                        onChange={handleChange}
                        className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                      />
                    </div>
                  </div>

                  {/* Password */}
                  <div className="tw-mb-4">
                    <label
                      htmlFor="password"
                      className="tw-block tw-text-md tw-font-medium tw-text-primary"
                    >
                      Password *
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                      className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                    />
                    {formErrors.password && (
                      <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                        {formErrors.password}
                      </p>
                    )}
                  </div>

                  {/* Confirm Password */}
                  <div className="tw-mb-4">
                    <label
                      htmlFor="confirmPassword"
                      className="tw-block tw-text-md tw-font-medium tw-text-primary"
                    >
                      Confirm Password *
                    </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
                    />
                    {formErrors.confirmPassword && (
                      <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                        {formErrors.confirmPassword}
                      </p>
                    )}
                  </div>
                  {/* Newsletter Subscription Checkbox */}
                  <div className="tw-mb-4">
                    <label
                      htmlFor="newsletterSubscribed"
                      className="tw-block tw-text-md tw-font-medium tw-text-primary"
                    >
                      Subscribe to Newsletter?
                    </label>

                    <input
                      type="checkbox"
                      id="newsletterSubscribed"
                      name="newsletterSubscribed"
                      checked={formData.newsletterSubscribed}
                      onChange={handleChange}
                      className="tw-mt-1 tw-p-2 tw-border tw-border-2 tw-rounded tw-appearance-none checked:tw-bg-primary "
                    />
                  </div>

                  {/* Preferences Checkboxes */}
                  {formData.newsletterSubscribed && (
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-md tw-font-medium tw-text-primary">
                        Preferences:
                      </label>
                      <div className="tw-flex tw-flex-wrap">
                        <label className="tw-inline-flex tw-items-center tw-mt-1 tw-mr-4">
                          <input
                            type="checkbox"
                            name="newsletterPreference"
                            value="Blogs"
                            onChange={handleChange}
                            className="tw-form-checkbox tw-border tw-border-2 tw-h-4 tw-w-4 tw-text-primary tw-appearance-none checked:tw-bg-primary"
                          />
                          <span className="tw-ml-2 tw-text-primary">Blogs</span>
                        </label>
                        <label className="tw-inline-flex tw-items-center tw-mt-1 tw-mr-4">
                          <input
                            type="checkbox"
                            name="newsletterPreference"
                            value="Recipes"
                            onChange={handleChange}
                            className="tw-form-checkbox tw-border tw-border-2  tw-h-4 tw-w-4 tw-text-primary tw-appearance-none checked:tw-bg-primary"
                          />
                          <span className="tw-ml-2 tw-text-primary">
                            Recipes
                          </span>
                        </label>
                        <label className="tw-inline-flex tw-items-center tw-mt-1 tw-mr-4">
                          <input
                            type="checkbox"
                            name="newsletterPreference"
                            value="News"
                            onChange={handleChange}
                            className="tw-form-checkbox tw-border tw-border-2 tw-h-4 tw-w-4 tw-text-primary tw-appearance-none checked:tw-bg-primary"
                          />
                          <span className="tw-ml-2 tw-text-primary">News</span>
                        </label>
                        <label className="tw-inline-flex tw-items-center tw-mt-1 tw-mr-4">
                          <input
                            type="checkbox"
                            name="newsletterPreference"
                            value="Events"
                            onChange={handleChange}
                            className="tw-form-checkbox tw-border tw-border-2 tw-h-4 tw-w-4 tw-text-primary tw-appearance-none checked:tw-bg-primary"
                          />
                          <span className="tw-ml-2 tw-text-primary">
                            Events
                          </span>
                        </label>
                        {/* Add more preferences checkboxes here */}
                      </div>
                    </div>
                  )}
                </>
              )}
              <button
                type="submit"
                className="tw-rounded-full tw-flex tw-text-white tw-bg-primary tw-border tw-border-4 tw-border-double tw-p-4  tw-w-1/3 tw-mx-auto hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-primary tw-justify-center "
                disabled={!isVerified}
              >
                Register
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
