import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import Modal from "react-modal";
import { BASE_ADMIN_URL } from "../../../utils/APIURLs";
import { getCookieValue } from "../../../utils/manageCookie";
import { toast } from "react-toastify";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px", // Adjust the maximum width as needed
    paddingTop: "10px", // Add padding as needed
    backgroundColor: "var(--lighter-color)",
    minHeight: "600px", // Adjust the maximum width as needed
    zIndex: "10000 !important",
    maxWidth: "100%",
  },
  overlay: {
    zIndex: "1000",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};
const AddEntity = ({ entityType, isOpen, setIsOpen }) => {
  const { categories, fetchAllEntities } = useAuth();
  const [newEntityDetails, setNewEntityDetails] = useState({
    title: "",
    subtitle: "",
    description: "",
    courseCategory: "",
    price: "",
    itemType: entityType,
  });

  const handleChange = (e) => {
    setNewEntityDetails({
      ...newEntityDetails,
      [e.target.id]: e.target.value,
    });
  };

  const handleAddNewOne = (e) => {
    e.preventDefault();
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios
      .post(`${BASE_ADMIN_URL}/addEntity`, newEntityDetails, config)
      .then((res) => {
        toast.success(`${entityType} added successfully`);
        setNewEntityDetails({
          title: "",
          subtitle: "",
          description: "",
          courseCategory: "",
          price: "",
          itemType: entityType,
        });
        fetchAllEntities();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to add course");
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Add new Entity"
      style={customModalStyles}
    >
      <div className="tw-flex tw-flex-col">
        <h1 className="tw-tex-primary tw-font-bold tw-text-center tw-mb-4">
          Add New {entityType}
        </h1>
        <div className="tw-w-full tw-pr-4 tw-mb-8 ">
          <form
            onSubmit={handleAddNewOne}
            className="tw-bg-white tw-p-6 tw-rounded tw-shadow-2xl tw-max-w-5xl tw-mx-auto"
          >
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="title"
              >
                Title
              </label>
              <input
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="title"
                type="text"
                placeholder="Title"
                value={newEntityDetails.title}
                onChange={handleChange}
              />
            </div>
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="subtitle"
              >
                Sub-Title
              </label>
              <input
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="subtitle"
                type="text"
                placeholder="Sub-Title"
                value={newEntityDetails.subtitle}
                onChange={handleChange}
              />
            </div>
            <div className="tw-mb-4">
              <label
                className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                id="description"
                type="text"
                placeholder="Description"
                value={newEntityDetails.description}
                onChange={handleChange}
              />
            </div>
            {entityType === "course" && (
              <div className="tw-mb-4">
                <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                  htmlFor="category"
                >
                  Category
                </label>
                <select
                  className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                  id="courseCategory"
                  value={newEntityDetails.courseCategory}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.title}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {entityType !== "demoClass" && (
              <div className="tw-mb-4">
                <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                  htmlFor="price"
                >
                  Price
                </label>
                <input
                  className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                  id="price"
                  type="number"
                  placeholder="Price"
                  value={newEntityDetails.price}
                  onChange={handleChange}
                />
              </div>
            )}
            <button
              className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 tw-bg-accent hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-accent tw-text-primary tw-rounded-full tw-w-42 tw-text-xl"
              onClick={handleAddNewOne}
            >
              Add {entityType}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddEntity;
