import moment from "moment";
import { useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
const localizer = momentLocalizer(moment);

const CustomToolbar = ({ label, onNavigate, onView }) => {
  return (
    <div className="tw-flex tw-justify-between tw-flex-row">
      <div className="tw-grid tw-grid-cols-2">
        <button
          className="tw-p-2 tw-border tw-border-2"
          onClick={() => onNavigate("PREV")}
        >
          Previous
        </button>
        <button
          className="tw-p-2 tw-border tw-border-2"
          onClick={() => onNavigate("NEXT")}
        >
          Next
        </button>
      </div>
      <div className="tw-text-2xl tw-font-bold">{label}</div>
      <div className="tw-grid tw-grid-cols-2">
        <button
          className="tw-p-2 tw-border tw-border-2"
          onClick={() => onView("month")}
        >
          Month
        </button>
        <button
          className="tw-p-2 tw-border tw-border-2"
          onClick={() => onView("week")}
        >
          Week
        </button>
      </div>
    </div>
  );
};
const MyCalendar = ({ events }) => {
  const calculateCalendarHeight = () => {
    const eventCount = events.length;
    const additionalEventHeight = Math.max(0, eventCount - 3) * 20; // Adjust this value as needed
    return 500 + additionalEventHeight;
  };

  const navigate = useNavigate();

  const handleEventClick = useCallback((event) => {
    navigate(`/courses/${event.title}/${event.subtitle}`);
  }, [navigate]);

  return (

    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{
        height: calculateCalendarHeight(),
        width: "100%",
        margin: "auto",
        padding: "20px",
        color: "black",
        backgroundColor: "white",
      }}
      views={["month", "week"]}
      //onDoubleClickEvent={(event) => navigate(`/courses/${event.title}/${event.subtitle}`)}
      onSelectEvent={handleEventClick}
      onDoubleClickEvent={handleEventClick}
      components={{
        toolbar: CustomToolbar,
      }}
    />
  );
};

export default MyCalendar;