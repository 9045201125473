import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { BASE_ADMIN_URL } from "../../../utils/APIURLs";
import { getCookieValue } from "../../../utils/manageCookie";
import { toast } from "react-toastify";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import AddEntity from "./AddEntity";
import EditEntity from "./EditEntity";
import { formattedDate } from "../../../utils/helpfulFunctions";
import PaginationComponent from "../../../components/PaginationComponent";
import ManageCategories from "../ManageCategory/ManageCategory";
import styles from "../../../components/styles/TableStyles";

const ManageEntity = () => {
  const [entityType, setEntityType] = useState("course");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { allEntities, fetchAllEntities } = useAuth();
  const [entities, setEntities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const itemsPerPage = 8;
  const dropdownRef = useRef(null);

  const closeDropdownMenu = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const expandEntities = (entities) => {
    const expanded = [];

    entities.forEach((entity) => {
      if (entity.timeSlots.length == 0) {
        expanded.push(entity);
      }
      entity.timeSlots.forEach((item, index) => {
        const expandedEntity = {
          ...entity,
          slotId: item._id,
          startDate: item.slot.startDate,
          endDate: item.slot.endDate,
          totalSeats: item.totalSeats,
          upcomingPriority: item.upcomingPriority,
          enrolledSeats: item.usersEnrolled.length,
        };
        expanded.push(expandedEntity);
      });
    });
    return expanded;
  };
  const getAllItems = () => {
    const expandedEntities = expandEntities(allEntities);
    setEntities(expandedEntities);
  };

  const handleDelete = (itemId, slotId) => {
    const authToken = getCookieValue("authToken");
    setIsDeleted(false);
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .delete(
        `${BASE_ADMIN_URL}/deleteEntity?itemId=${itemId}&slotId=${slotId}`,
        config,
      )
      .then((res) => {
        console.log(res);
        setIsDeleted(true);
        fetchAllEntities();
        setEntities(allEntities);
        toast.success("Entity Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdownMenu);
    return () => {
      document.removeEventListener("mousedown", closeDropdownMenu);
    };
  }, [dropdownOpen]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = entities.slice(indexOfFirstItem, indexOfLastItem);

  const handleAddNewEntity = (entityType) => {
    setEntityType(entityType);
    setIsOpenAdd(true);
  };

  useEffect(() => {
    getAllItems();
  }, [allEntities]);

  return (
    <div className="tw-container tw-mx-auto">
      <ManageCategories />
      <h1 className="tw-text-4xl tw-font-bold tw-text-center tw-mb-8">
        Manage Entities
      </h1>
      {isOpenAdd && (
        <AddEntity
          entityType={entityType}
          isOpen={isOpenAdd}
          setIsOpen={setIsOpenAdd}
        />
      )}
      {isOpenEdit && (
        <EditEntity
          entityType={entityType}
          editEntity={editEntity}
          isOpenEdit={isOpenEdit}
          setIsOpenEdit={setIsOpenEdit}
        />
      )}
      <div className="tw-text-right">
        <button onClick={toggleDropdown} ref={dropdownRef}>
          <IoMdAddCircleOutline style={{ fontSize: "32px" }} />
          {dropdownOpen && (
            <div className="tw-absolute tw-mt-1 tw-bg-white tw-shadow-md tw-border tw-border-gray-300 tw-rounded">
              <button
                className="tw-block tw-w-full tw-py-2 tw-px-4 tw-text-left hover:tw-bg-secondary hover:tw-text-white"
                onClick={() => handleAddNewEntity("course")}
              >
                Add Course
              </button>
              <button
                className="tw-block tw-w-full tw-py-2 tw-px-4 tw-text-left hover:tw-bg-secondary hover:tw-text-white"
                onClick={() => handleAddNewEntity("demoClass")}
              >
                Add Demo Class
              </button>
              <button
                className="tw-block tw-w-full tw-py-2 tw-px-4 tw-text-left hover:tw-bg-secondary hover:tw-text-white"
                onClick={() => handleAddNewEntity("recipe")}
              >
                Add Recipe
              </button>
            </div>
          )}
        </button>
      </div>
      <div style={{overflowX: "auto"}}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Sr No.</th>
            <th style={styles.th}>Title</th>
            <th style={styles.th}>Sub Title</th>
            <th style={styles.th}>Item Type</th>
            <th style={styles.th}>Category</th>
            <th style={styles.th}>Slot</th>
            <th style={styles.th}>Price</th>
            <th style={styles.th}>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((entity, index) => (
            <tr key={index}>
              <td style={styles.td}>{index + 1}</td>
              <td style={styles.td}>{entity.title}</td>
              <td style={styles.td}>{entity.subtitle}</td>
              <td style={styles.td}>{entity.itemType}</td>
              {entity.itemType === "course" ? (
                <td style={styles.td}>{entity.courseCategory.title}</td>
              ) : (
                <td style={styles.td}>-</td>
              )}
              <td style={styles.td}>
                {entity.timeSlots.length == 0 ? (
                  "Not Scheduled"
                ) : (
                  <>
                    {formattedDate(entity.startDate)} -{" "}
                    {formattedDate(entity.endDate)}
                  </>
                )}
              </td>
              <td style={styles.td}>{entity.price}</td>
              <td style={styles.td}>
                <button
                  className="tw-bg-accent hover:tw-bg-secondary tw-text-black tw-p-2 tw-rounded tw-mr-2"
                  onClick={() => {
                    setEditEntity(entity);
                    setEntityType(entity.itemType);
                    setIsOpenEdit(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="tw-bg-red-300 hover:tw-bg-red-500 tw-text-black tw-p-2 tw-rounded tw-mr-2"
                  onClick={() => handleDelete(entity._id, entity.slotId)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        totalItems={entities.length}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
    </div>
  );
};

export default ManageEntity;
