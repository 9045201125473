const POLICY_QUERY = `
    policiesCollection {
        items {
            name
            policyContent {
                json
            }
        }
    }
`;

export default POLICY_QUERY;
