const FAQ_QUERY = `
    faqCollection(limit: 10){
        items {
            id
            question
            answer{
                json
            }
        }
    }
`;

export default FAQ_QUERY;
