import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import richTextRender from "../../utils/richTextRender";

const ChefProfiles = ({ chefsData }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let selectedChefId = queryParams.get("selectedChefId");
  if (!selectedChefId) selectedChefId = 0;
  const selectedChefRef = useRef(null);

  useEffect(() => {
    if (selectedChefRef.current) {
      selectedChefRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedChefRef]);

  const chefs = [...chefsData];
  chefs.sort((a, b) => a.id - b.id);

  return (
    <div className="tw-container tw-mx-auto  lg:tw-w-4/5 md:tw-w-full ">
      {chefs.map((chef, index) => (
        <div
          ref={
            selectedChefId.toString() === chef.id.toString()
              ? selectedChefRef
              : null
          }
          className={`tw-flex tw-flex-col tw-mb-8 tw-items-center tw-my-auto ${index % 2 === 0 ? "tw-flex-row-reverse" : ""}`}
          key={chef.id}
        >
          <div className={`tw-flex md:tw-flex-row tw-flex-col`}>
            <div
              className={`md:tw-w-1/3 tw-mb-8 md:tw-mb-0  tw-flex tw-items-center tw-justify-center ${index % 2 === 0 ? "" : "md:tw-order-2"}`}
              data-aos="fade-up"
            >
              <img
                src={chef.coverImage.url}
                alt={chef.name}
                className="tw-w-full lg:tw-h-128 tw-h-100 "
              />
            </div>
            <div
              className={`md:tw-w-2/3 tw-p-4 md:tw-p-8 ${index % 2 === 0 ? "" : "md:tw-order-1"}`}
              data-aos="fade-down"
            >
              <h2 className="tw-text-primary tw-font-semibold tw-mb-4 md:tw-text-left tw-text-center">
                {chef.name}
              </h2>
              <div className="tw-flex tw-items-center tw-text-lg tw-text-gray-800 tw-text-justify tw-mb-2">
                <div className="tw-flex tw-items-center tw-mr-6">
                  <img
                    src={chef.instaIcon.url}
                    alt="Instagram"
                    className="tw-h-10 tw-w-10"
                  />
                  <span className="tw-ml-2 tw-flex tw-items-center">{richTextRender(chef.instaHandle)}</span>
                </div>
                <div className="tw-flex tw-items-center">
                  <img
                    src={chef.linkedInIcon.url}
                    alt="LinkedIn"
                    className="tw-h-10 tw-w-10 tw-rounded-md"
                  />
                  <span className="tw-ml-2 tw-flex tw-items-center">{richTextRender(chef.linkedInHandle)}</span>
                </div>
              </div>
              <p className="tw-text-lg tw-text-gray-800 tw-text-justify">
                {richTextRender(chef.bodyText)}
              </p>
              
              {chef.achievements && (
                <p className="tw-mt-4 tw-font-bold tw-text-xl tw-text-secondary ">
                  Achievements :{" "}
                </p>
              )}
              {chef.achievements && (
                <>
                  <ul className="tw-list-disc tw-pl-8 tw-mt-2 tw-text-gray-700 tw-text-lg tw-text-justify">
                    {
                      chef.achievements.map((achievement, index) => (
                        <li key={index} className="tw-text-lg tw-text-primary">
                          {achievement}
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </div>
          </div>
          <hr className="tw-border tw-border-1 tw-border-gray-500 tw-w-full tw-mx-4 tw-my-4" />
        </div>
      ))}
    </div>
  );
};

export default ChefProfiles;
