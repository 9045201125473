import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";
import ProtectedRoute from "./ProtectedRoutes";
import Register from "./pages/Register/Register";
import NavbarComponent from "./components/Navbar";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/Login/ResetPassword";
import About from "./pages/About/About";
import Academy from "./pages/About/Academy";
import CoursesPage from "./pages/Courses/CoursesPage";
import CourseDetail from "./pages/Courses/CourseDetail";
import SubCoursesPage from "./pages/Courses/SubCoursesPage";
import NewsEventsPage from "./pages/NewsEvents/NewsEventsPage";
import NewsEventsDetail from "./pages/NewsEvents/NewsEventsDetail";
import ContactPage from "./pages/Contact/ContactPage";
// import Gallery from "./pages/Gallery/Gallery";
import Course from "./pages/Courses/Course";
import CourseCart from "./pages/Courses/CourseCart";
import UserProfilePage from "./pages/Profile/Profile";
import ChefProfiles from "./pages/Chefs/ChefProfiles";
// import OnSiteTour from "./pages/Tours/OnSiteTour";
import PaymentStatus from "./pages/Courses/PaymentStatus";
// import OnSiteDemo from "./pages/Tours/OnSiteDemo";
import Faq from "./pages/FAQs/Faq";
import Policies from "./pages/FAQs/Policies";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import StickyButtons from "./components/SideStickyButtons";

// Admin Components
import AdminRegister from "./admin/pages/AdminRegister";
import CourseRegister from "./admin/pages/CourseRegister";
import AdminDashboard from "./admin/pages/AdminDashboard";
import ViewEnrollmentDetails from "./admin/pages/ViewEnrollmentDetails";
import ViewItemWithEnrolledUsers from "./admin/pages/ViewItemWithEnrolledUsers";
import AddCourseCategory from "./admin/pages/ManageCategory/AddCourseCategory";
import ManageEntity from "./admin/pages/ManageEntities/ManageEntity";
import ScheduleEntity from "./admin/pages/ManageEntities/ScheduleEntity";
import ViewAllUsers from "./admin/pages/ViewAllUsers";

import Modal from "react-modal";
import { AuthProvider } from "./AuthContext";

import { getCookieValue } from "./utils/manageCookie";
// import data from "./pages/FAQs/FAQdata";

// graphQL for CMS fetching
import { useQuery } from "@apollo/client";
import GET_CMS_DATA_QUERY from "./utils/query";
import AOS from "./AOS";
import ScrollToTop from "./ScrollToTop";

Modal.setAppElement("#root");

function App() {
  const { data } = useQuery(GET_CMS_DATA_QUERY);
  const role = getCookieValue("role");

  if (data) {
    return (
      <>
        <AuthProvider>
          <Router>
            <AppContent data={data} role={role} />
          </Router>
        </AuthProvider>
      </>
    );
  }
}

function AppContent({ data, role }) {
  const location = useLocation();
  const { trackPageView } = useGoogleAnalytics();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location, trackPageView]);

  return (
    <>
      <ScrollToTop />
      <div className="App playfair-display">
        <div className="playfair-display navbar">
          <NavbarComponent />
        </div>
        <ToastContainer />
        <StickyButtons />
        <div className="content">
          <Routes>
            <Route path="/about-academy" element={<Academy />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/"
              element={
                <About testimonialData={data.testimonialCollection.items} />
              }
            />
            <Route path="/courses" element={<Course />} />
            <Route
              path="/courses/:title/:subtitle?"
              key={window.location.pathname}
              element={<CourseDetail />}
            />
            <Route path="/courses/title/:title" element={<SubCoursesPage />} />
            <Route
              path="/courses/category/:categoryId"
              element={<CoursesPage />}
            />
            <Route
              path="/news-events"
              element={
                <NewsEventsPage
                  newsEventsData={data.newsEventsCollection.items}
                />
              }
            />
            <Route
              path="/news-events/:newsEventId"
              element={<NewsEventsDetail />}
            />
            <Route
              path="/chefs"
              element={
                <ChefProfiles chefsData={data.chefProfilesCollection.items} />
              }
            />
            <Route
              path="/contact-us/:title?/:subtitle?"
              element={<ContactPage />}
            />
            {/* <Route
                    path="/gallery"
                    element={
                      <Gallery
                        imagesData={data.galleryImagesCollection.items}
                      />
                    }
                  /> */}
            <Route path="/payment-status" element={<PaymentStatus />} />
            <Route path="/profile" element={<UserProfilePage />} />
            <Route path="/cart" element={<CourseCart />} />
            {/* <Route path="/onsite-tour" element={<OnSiteTour />} />
                  <Route path="/onsite-demo" element={<OnSiteDemo />} /> */}
            <Route
              path="/faq"
              element={<Faq data={data.faqCollection.items} />}
            />
            <Route
              path="/policies"
              element={<Policies data={data.policiesCollection.items} />}
            />

            {/* Protected Routes*/}
            <Route path="/admin/register" element={<AdminRegister />} />
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute role={role}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/courseRegister"
              element={
                <ProtectedRoute role={role}>
                  <CourseRegister />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/viewEnrollmentDetails"
              element={
                <ProtectedRoute role={role}>
                  <ViewEnrollmentDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/viewEnrolledUsers/:itemId/:slotId?"
              element={
                <ProtectedRoute role={role}>
                  <ViewItemWithEnrolledUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/manageEntity"
              element={
                <ProtectedRoute role={role}>
                  <ManageEntity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/scheduleEntity"
              element={
                <ProtectedRoute role={role}>
                  <ScheduleEntity />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/addCourseCategory"
              element={
                <ProtectedRoute role={role}>
                  <AddCourseCategory />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/viewAllUsers"
              element={
                <ProtectedRoute role={role}>
                  <ViewAllUsers />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <Footer className="footer" />
      </div>
    </>
  );
}

export default App;
