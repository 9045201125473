// utils/validation.js

export const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  export const isValidPhone = (phone) => {
    // Basic phone number validation for Indian numbers
    // Assumes 10-digit number, optionally starting with +91 or 0
    const phoneRegex = /^\+?\d{10,15}$/;
    return phoneRegex.test(phone);
  };