import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { BASE_ADMIN_URL } from "../../../utils/APIURLs";
import { getCookieValue } from "../../../utils/manageCookie";
import { toast } from "react-toastify";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "500px", // Adjust the maximum width as needed
    paddingTop: "10px", // Add padding as needed
    backgroundColor: "var(--lighter-color)",
    minHeight: "400px", // Adjust the maximum width as needed
    zIndex: "100000000 !important",
    maxWidth: "100%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const AddCourseCategory = ({ isOpenAdd, setIsOpenAdd }) => {
  const [newCategoryDetails, setNewCategoryDetails] = useState({
    title: "",
    description: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setNewCategoryDetails((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(`${BASE_ADMIN_URL}/addCourseCategory`, newCategoryDetails, config)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Category Added Successfully");
          setNewCategoryDetails({
            title: "",
            description: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      isOpen={isOpenAdd}
      onRequestClose={() => setIsOpenAdd(false)}
      contentLabel="Add Course Category"
      style={customModalStyles}
    >
      <div className="tw-container tw-mx-auto ">
        <h1 className="tw-text-4xl tw-font-bold tw-text-center tw-mb-8">
          Add a Course Category
        </h1>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <div className="tw-w-full tw-pr-4 tw-mb-8 ">
            <form className="tw-bg-white tw-p-6 tw-rounded tw-shadow-2xl tw-max-w-5xl tw-mx-auto">
              <div className="tw-mb-4">
                <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                  id="title"
                  type="text"
                  placeholder="Title"
                  value={newCategoryDetails.title}
                  onChange={handleChange}
                />
              </div>

              <div className="tw-mb-4">
                <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
                  htmlFor="description"
                >
                  Description
                </label>
                <textarea
                  className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                  id="description"
                  type="text"
                  placeholder="Description"
                  value={newCategoryDetails.description}
                  onChange={handleChange}
                />
              </div>

              <button
                className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 tw-bg-accent hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-accent tw-text-primary tw-rounded-full tw-w-42 tw-text-xl"
                onClick={handleAddCategory}
              >
                Add Category
              </button>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCourseCategory;
