// UserProfilePage.js
import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import EmotionIcon from "../../components/EmotionIcon";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import axios from "axios";
import { formattedDate } from "../../utils/helpfulFunctions";
import UserProfileDetails from "./UserProfileDetails";

const ShowItems = ({ itemsList, itemType }) => {
  return (
    <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 tw-mx-10">
      {itemsList.map((item, index) => (
        <div
          key={index}
          className="tw-p-8 tw-rounded-lg tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-shadow-2xl tw-bg-lightest"
        >
          <div className="tw-flex tw-flex-col ">
            <h4 className=" tw-text-secondary tw-font-bold tw-mb-2">{item.title}</h4>
            <h5 className="tw-text-primary tw-font-bold tw-mb-2">{item.subtitle} </h5>
            <p className="tw-mb-2 tw-text-accent tw-font-medium">
              <span className="tw-text-primary tw-font-bold">Date:</span>{" "}
              {formattedDate(item.startDate)} - {formattedDate(item.endDate)}
            </p>
            {itemType === "course" && (
              <>
                <p className="tw-mb-2">
                  <span className="tw-text-primary tw-font-bold">
                    Category:
                  </span>{" "}
                  {item.courseCategoryTitle}
                </p>
                <p className="tw-mb-2">
                  <span className="tw-text-primary tw-font-bold">Price:</span> ₹{" "}
                  {item.price}
                </p>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
const AlreadyBoughtItems = () => {
  const {  fetchBoughtItems, boughtItems } = useAuth();

  useEffect(() => {
    fetchBoughtItems();
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-items-center ">
      <h1 className="tw-text-primary tw-font-bold tw-my-4 ">
        Already Bought Items{" "}
      </h1>
      {!boughtItems ? (
        <div className="tw-flex tw-flex-col tw-mx-auto tw-items-center">
          <EmotionIcon name="sad" />
          <h1 className=" tw-font-bold tw-my-4 tw-text-primary">
            No items Bought Yet
          </h1>
        </div>
      ) : (
        <>
          {boughtItems.courses && boughtItems.courses.length > 0 && (
            <div className="tw-mb-8">
              <h3 className="tw-text-secondary tw-font-bold tw-mb-4 tw-text-center">Courses</h3>
              <ShowItems itemsList={boughtItems.courses} itemType="course" />
            </div>
          )}
          {boughtItems.demoClass && boughtItems.demoClass.length > 0 && (
            <div className="tw-mb-8">
                <h3 className="tw-text-secondary tw-font-bold tw-mb-4">Demo Classes</h3>
              <ShowItems
                itemsList={boughtItems.demoClass}
                itemType="demoClass"
              />
            </div>
          )}
          {boughtItems.recipes && boughtItems.recipes.length > 0 && (
            <div className="tw-mb-8">
                <h3 className="tw-text-secondary tw-font-bold tw-mb-4">Recipes</h3>
              <ShowItems itemsList={boughtItems.recipes} itemType="recipe" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const UserProfilePage = () => {
  return (
    <div className="tw-container tw-mx-auto tw-flex  tw-flex-col md:tw-flex-row  tw-my-16">
      <div className="md:tw-w-3/5 tw-p-4 ">
        <AlreadyBoughtItems />
      </div>
      <div className="md:tw-w-2/5 tw-w-full tw-p-4 ">
        <UserProfileDetails />
      </div>
    </div>
  );
};

export default UserProfilePage;
