import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formattedDate } from "../../utils/helpfulFunctions";
import PaginationComponent from "../../components/PaginationComponent";

const MAX_DESCRIPTION_LENGTH = 300;
const NewsEventsPage = ({ newsEventsData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [newsItems, setNewsItems] = useState([]);
  const totalItems = newsEventsData.length;
  const itemsPerPage = 3;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // sort on the basis of date
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  const sortedData = [...newsEventsData]; // Create a copy of the array
  sortedData.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="tw-container tw-mx-auto tw-mt-8 tw-text-center">
      <h1 className=" tw-text-center tw-text-4xl tw-font-bold  tw-px-auto tw-text-primary tw-mb-8">
        Updates & Blogs
      </h1>
      <div
        className={`tw-grid tw-grid-cols-1 md:tw-grid-cols-2  tw-gap-4 tw-mx-10 tw-justify-center tw-mx-auto  tw-px-4
      ${totalItems === 2 ? "lg:tw-grid-cols-2 lg:tw-px-32" : ""}
      ${totalItems === 1 ? "lg:tw-grid-cols-1 lg:tw-px-96" : ""}
      ${totalItems > 2 ? "lg:tw-grid-cols-3 " : ""}
      `}
      >
        {currentItems.map((entry) => (
          <div
            key={entry.id}
            className="tw-bg-lightest tw-p-4 tw-rounded-md tw-shadow-md tw-relative tw-h-128"
          >
            <img
              src={entry.coverImage.url}
              alt={entry.heading}
              className="tw-mb-2 tw-w-full tw-h-64 tw-rounded-md"
              data-aos="zoom-in"
            />
            {/* <p className="tw-text-sm tw-text-gray-500">{entry.type}</p> */}
            <p className="tw-text-md tw-text-primary">
              {formattedDate(entry.eventDate)}
            </p>
            <h2 className="tw-text-xl tw-text-secondary tw-font-bold tw-mb-2" data-aos="fade-up">
              {entry.heading}
            </h2>
            <p className="tw-text-primary tw-mb-8" data-aos="fade-up">
              {entry.summary.length > MAX_DESCRIPTION_LENGTH
                ? `${entry.summary.slice(0, MAX_DESCRIPTION_LENGTH)}...`
                : entry.summary}
            </p>
            <p className="tw-text-right tw-text-primary" data-aos="fade-up">
              Author: {entry.author}
            </p>
            <Link
              to={{
                pathname: `/news-events/${entry.id}`,
              }}
              data-aos="fade-down"
              className="tw-border  tw-font-bold  hover:tw-bg-secondary tw-absolute tw-bottom-4 tw-left-4 tw-px-4 tw-py-2 tw-bg-accent tw-text-primary hover:tw-text-primary tw-rounded-full tw-transition tw-duration-300"
            >
              Learn More
            </Link>
          </div>
        ))}
      </div>
      <div className="tw-mt-16"></div>
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default NewsEventsPage;
