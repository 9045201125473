import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_ADMIN_URL } from "../../utils/APIURLs";
import { useAuth } from "../../AuthContext";
import { getCookieValue } from "../../utils/manageCookie";
import styles from "../../components/styles/TableStyles";

const ViewItemWithEnrolledUsers = () => {
  const { itemId, slotId } = useParams();
  const [allUsers, setAllUsers] = useState([]);
  const [metaData, setMetaData] = useState({});

  const fetchEnrolledUsers = async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.get(
        `${BASE_ADMIN_URL}/getUsersForSlot?itemId=${itemId}&slotId=${slotId}`,
        config,
      );
      const { slot, users, item } = res.data;
      console.log(users)
      setAllUsers(users);
      setMetaData({...slot, ...item});
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveUser = async (userId) => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.delete(`${BASE_ADMIN_URL}/removeUserFromCourse`, {
        data: {
          userId,
          courseId: itemId,
          slotId
        },
        ...config,
      });
      fetchEnrolledUsers();
      toast.success("User Removed Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Failed to Remove User");
    }
  }

  useEffect(() => {
    fetchEnrolledUsers();
  }, []);

  return (
    <div className="tw-container tw-mx-auto tw-pt-8">
      <h1 className="tw-text-center ">View Item With Enrolled Users</h1>
      <h2 className="tw-text-secondary tw-text-center">{metaData.title} {metaData.subtitle}</h2>
      <h3 className="tw-text-accent tw-text-center">
        Total Seats : {metaData.totalSeats}
      </h3>
      {allUsers.length === 0 && (
        <h3 className="tw-text-accent tw-text-center">No Users Enrolled</h3>
      )}
      {allUsers.length === metaData.totalSeats && (
        <h3 className="tw-text-accent tw-text-center">All Seats are Booked</h3>
      )}

      <table style={styles.table}>
        <tr>
          <th style={styles.th}>Sr No.</th>
          <th style={styles.th}>User Name</th>
          <th style={styles.th}>User Email</th>
          <th style={styles.th}>User Phone</th>
          <th style={styles.th}>Payment Mode</th>
          <th style={styles.th}>Payment Status</th>
          <th style={styles.th}>Transaction Id</th>
          <th style={styles.th}>Action</th>
        </tr>
        {allUsers.map((user, index) => {
          return (
            <tr>
              <td style={styles.td}>{index + 1}</td>
              <td style={styles.td}>
                {user.firstName} {user.lastName}
              </td>
              <td style={styles.td}>{user.email}</td>
              <td style={styles.td}>{user.phone}</td>
              <td style={styles.td}>{user.payment?.mode}</td>
              <td style={styles.td}>{user.payment?.remarks}</td>
              <td style={styles.td}>{user.payment?.transactionId}</td>
              <td style={styles.td}>
                <button className="tw-bg-danger tw-text-white tw-p-1 tw-rounded"
                  onClick={() => handleRemoveUser(user._id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default ViewItemWithEnrolledUsers;
