import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { differenceInDays } from "../../utils/helpfulFunctions";

const SearchComponent = ({ courses, categories, categoryTitle }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([courses]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sortBy, setSortBy] = useState("");

  const navigate = useNavigate();

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const filterCourses = () => {
    let filtered = courses;
    if (searchText) {
      filtered = filtered.filter((course) =>
        course.title.toLowerCase().includes(searchText.toLowerCase()) 
        || course.subtitle.toLowerCase().includes(searchText.toLowerCase())
        || course.description.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(
        (course) => course.courseCategory === selectedCategory,
      );
    }

    if (sortBy === "price-lh") {
      filtered.sort((a, b) => a.price - b.price);
    } else if (sortBy === "price-hl") {
      filtered.sort((a, b) => b.price - a.price);
    } else if (sortBy === "duration-sl") {
      filtered.sort((a, b) => {
        if (a.timeSlots.length === 0 || b.timeSlots.length === 0) return 0;
        const dur1 = differenceInDays(
          a.timeSlots[0].slot.endDate,
          a.timeSlots[0].slot.startDate,
        );
        const dur2 = differenceInDays(
          b.timeSlots[0].slot.endDate,
          b.timeSlots[0].slot.startDate,
        );
        return dur1 - dur2;
      });
    } else if (sortBy === "duration-ls") {
      filtered.sort((a, b) => {
        if (a.timeSlots.length === 0 || b.timeSlots.length === 0) return 0;
        const dur1 = differenceInDays(
          a.timeSlots[0].slot.endDate,
          a.timeSlots[0].slot.startDate,
        );
        const dur2 = differenceInDays(
          b.timeSlots[0].slot.endDate,
          b.timeSlots[0].slot.startDate,
        );
        return dur2 - dur1;
      });
    } else if(sortBy === "") {
      filtered.sort((a, b) => {
        return a.order - b.order;
      })
    }
    console.log(filtered)
    setFilteredCourses(prevFilteredCourses => [...filtered]);
  };

  useEffect(() => {
    filterCourses();
  }, [searchText, selectedCategory, sortBy,courses]);

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-col tw-space-y-3 tw-justify-center tw-items-center">
        <input
          className="md:tw-w-2/3 tw-w-4/5 tw-p-3 tw-border tw-border-2 tw-rounded-lg"
          type="text"
          value={searchText}
          onChange={handleSearchTextChange}
          placeholder="Search by text"
        />
        {categories && (
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            className=" tw-p-2 md:tw-w-1/3 tw-w-4/5 tw-border tw-border-2 tw-rounded-md"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.title}
              </option>
            ))}
          </select>
        )}
        {categoryTitle !== "Services" && (
          <select
            value={sortBy}
            onChange={handleSortByChange}
            className="tw-p-2 md:tw-w-1/5 tw-w-3/5 tw-border tw-border-2 tw-rounded-md tw-mb-4"
          >
            <option value="">Sort By</option>
            <option value="price-lh">Price-low to high</option>
            <option value="price-hl">Price-high to low</option>
            <option value="duration-sl">Duration-short to long</option>
            <option value="duration-ls">Duration-long to short</option>
          </select>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-items-center " data-aos="fade-up">
        {filteredCourses.map((course) => (
          <div className="tw-flex md:tw-flex-row tw-flex-col tw-my-8 tw-w-4/5 md:tw-w-2/3  tw-rounded-lg tw-shadow-2xl tw-bg-lightest">
            <div
              key={course._id}
              className="tw-relative  tw-overflow-hidden tw-shadow-md  md:tw-w-1/3 tw-bg-lightest"
            >
              <img
                src={course.entityImage}
                alt={course.title}
                className="tw-w-full tw-h-full tw-object-cover tw-object-center tw-opacity-60"
              />
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mx-4 md:tw-w-2/3 tw-bg-lightest">
              <h2 className="tw-text-2xl tw-font-bold tw-text-secondary tw-mb-4 tw-text-center tw-mt-3">
                {course.title}
                {course.subtitle && (
                  <p className="tw-text-primary"> {course.subtitle}</p>
                )}
              </h2>
              <p className="tw-text-lg tw-font-semi tw-mb-2 tw-text-center">
                {course.description}
              </p>
              <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-6">
                <button
                  className="tw-bg-accent tw-inline-block tw-border tw-border-double tw-font-bold  tw-px-4 tw-py-2 tw-rounded-full tw-transition tw-duration-300 hover:tw-bg-secondary tw-text-primary"
                  onClick={() => navigate(`/courses/${course.title}/${course.subtitle}`)}
                >
                  View Details
                </button>
              </div>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchComponent;
