const formattedDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
};

const differenceInDays = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

const getDefaultDateTime = (time) => {
  const today = new Date();
  const [hours, minutes] = time.split(":");
  console.log(hours, minutes)
  const defaultDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    hours,
    minutes,
  );
  const formattedDate = `${defaultDateTime.getFullYear()}-${String(defaultDateTime.getMonth() + 1).padStart(2, "0")}-${String(defaultDateTime.getDate()).padStart(2, "0")}T${String(defaultDateTime.getHours()).padStart(2, "0")}:${String(defaultDateTime.getMinutes()).padStart(2, "0")}`;
  return formattedDate;
};

export { formattedDate, differenceInDays, getDefaultDateTime };
