const CHEF_QUERY = `
    chefProfilesCollection(limit: 3) {
      items {
        id
        name
        coverImage {
          url
        }
        mainImage {
          url
        }
        role
        bodyText {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
              }
            }
            assets {
              block {
                sys {
                  id
                }
                url
              }
            }
          }
        }
        instaHandle {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
              }
            }
            assets {
              block {
                sys {
                  id
                }
                url
              }
            }
          }
        }
        instaIcon {
          url
        }
        linkedInHandle {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
              }
            }
            assets {
              block {
                sys {
                  id
                }
                url
              }
            }
          }
        }
        linkedInIcon {
          url
        }      
      }
    }
`;

export default CHEF_QUERY;
