import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { differenceInDays, formattedDate  } from "../utils/helpfulFunctions";
import LazyImage from "./LazyLoadImage";

const CourseIntake = () => {
  const { allCourses } = useAuth();
  const [upcomingCourses, setUpcomingCourses] = useState([]);

  useEffect(() => {
    const today = new Date();
    const allSlots = allCourses.reduce((acc, course) => {
      if (!course.timeSlots || course.timeSlots.length === 0) return acc;
      return [...acc, ...course.timeSlots.map(slot => ({
        ...slot,
        ...course,
      }))];
    }, []);

    // first filter out the slots that are in the past
    const filteredSlots = allSlots.filter(slot => new Date(slot.slot.startDate) > today);

    const sortedSlots = filteredSlots.sort((a, b) => {
      if (a.upcomingPriority !== b.upcomingPriority) {
        return a.upcomingPriority - b.upcomingPriority;
      }
      return new Date(a.slot.startDate) - new Date(b.slot.startDate);
    });

    // Filter and extract the top courses
    let topCourses = []
    if (sortedSlots) {
      topCourses = sortedSlots.slice(0, 3);
    }
    setUpcomingCourses(topCourses);
  }, [allCourses]);

  return (
    <>
      {upcomingCourses.length !== 0 && (
        <div className="tw-container tw-mx-auto tw-mt-20 tw-flex tw-flex-col tw-items-center ">
          <h1 className="tw-text-5xl tw-font-bold tw-text-center tw-text-primary">Upcoming Courses</h1>
          <div className="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 ">
            {upcomingCourses.map((course, index) => (
              <div
                key={index}
                className="tw-m-4 tw-p-4 tw-border tw-rounded-md tw-shadow-md tw-flex tw-flex-col tw-items-center tw-bg-lightest"
              > 
                {/* <LazyImage 
                  src={course.entityImage}
                  alt={course.title}
                  className="fade-in-image tw-w-full tw-h-[256px] tw-rounded-md"
                /> */}

                <img
                  src={course.entityImage}
                  alt={course.title}
                  className="fade-in-image tw-w-full tw-h-[256px] tw-rounded-md"
                  data-aos="fade-right"
                />
                <h2 className="tw-text-2xl tw-font-bold tw-mt-2 tw-text-secondary" data-aos="fade-up">
                  {course.title}
                  <span className="tw-text-primary"> {course.subtitle}</span>
                </h2>
                <hr className="tw-w-1/2 tw-mb-4 tw-border tw-border-1 tw-border-primary" />
                <p data-aos="fade-up" className="tw-text-primary">
                  {course.description.slice(0, 100)}...
                </p>
                <hr className="tw-w-full tw-mb-4 tw-border tw-border-2 tw-border-primary" />
                <p data-aos="fade-up" className="tw-text-primary tw-font-bold">
                  Next Intake: {formattedDate(course.slot.startDate)}
                </p>

                <Link
                  to={`/courses/${course.title}/${course.subtitle}`}
                  className="tw-bg-accent tw-opacity-90 tw-inline-block tw-border tw-border-double  tw-font-bold tw-text-black tw-px-4 tw-py-2 tw-rounded-full tw-transition tw-duration-300 hover:tw-bg-secondary hover:tw-bg-opacity-60 hover:tw-text-primary"
                >
                  Know More
                </Link>
              </div>
            ))}
          </div>
        </div>
      )
      }
    </>
  );
};

export default CourseIntake;
