import React from "react";
import { CTooltip, CButton } from "@coreui/react";

const CustomToolTipButton = ({ content, placement, children }) => {
  return (
    <CTooltip content={content} placement={placement}>
      <CButton color="white" style={{ padding: "0px" }}>
        {children}
      </CButton>
    </CTooltip>
  );
};

export default CustomToolTipButton;
