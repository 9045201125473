import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import SearchBar from "./SearchBar";


const SubCoursesPage = () => {
    const { title } = useParams();
    const { allCourses } = useAuth();
    const [filteredCourses, setFilteredCourses] = useState([]);

    useEffect(() => {
        const filtered = allCourses.filter((course) => {
            return course.title === title && course.subtitle !== "";
        });

        filtered.sort((a, b) => {
            return a.order - b.order;
        });
        console.log(filtered)
        setFilteredCourses(filtered);
    }, [title, allCourses]);


    return (
        <div className="tw-container tw-mx-auto tw-mt-32">
            <SearchBar courses={filteredCourses}  />
        </div>
    );

}

export default SubCoursesPage;