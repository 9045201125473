const NEWS_EVENTS_QUERY = `
    newsEventsCollection(limit: 0) {
      items {
        id
        heading
        coverImage {
          url
        }
        headerImage {
          url
        }
        summary
        bodyImagesCollection {
          items {
            url
          }
        }
        bodyText{
         json
          links {
            
            assets {
              block {
                sys {
                  id
                }
                url
              }
            }
          }
            
        }
        eventDate
        author
      }
    }
`;

export default NEWS_EVENTS_QUERY;
