import { ApolloClient, InMemoryCache } from "@apollo/client";
import config from "./config";
console.log(config);
const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${config.spaceId}`,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${config.accessToken}`,
  },
});

export default client;
