const ENTITY_DETAILS_QUERY = `
   entityDetailsCollection {
         items {
            entityId
            entityTitle
            faqs
            entityImage{
                fileName
                url
            }
            entityExtraInfo{
                json
            }
            order
        }
    }
`;

export default ENTITY_DETAILS_QUERY;



