import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-modal";
import PaginationComponent from "../../components/PaginationComponent";
import PhoneInput from "react-phone-number-input";
import { BASE_ADMIN_URL } from "../../utils/APIURLs";
import styles from "../../components/styles/TableStyles";
import { getCookieValue } from "../../utils/manageCookie";
import { useAuth } from "../../AuthContext";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px", // Adjust the maximum width as needed
    paddingTop: "10px", // Add padding as needed
    backgroundColor: "#fff",
    minHeight: "600px", // Adjust the maximum width as needed
    zIndex: "10000 !important",
    maxWidth: "100%",
  },
  overlay: {
    zIndex: "1000",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const ViewAllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [editableUserId, setEditableUserId] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchAllUsers = async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.get(`${BASE_ADMIN_URL}/getUsers`, config);
      setAllUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch users on initial render or edit
  useEffect(() => {
    fetchAllUsers();
  }, [isOpen]);

  const handleDelete = async (id) => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.delete(
        `${BASE_ADMIN_URL}/deleteUser/${id}`,
        config,
      );
      toast.success(res.data.message);
      fetchAllUsers();
    } catch (error) {
      console.log(error);
    }
  };
  
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="tw-container tw-mx-auto tw-pt-8">
      <h1 className="tw-text-center ">View All Users</h1>
      {isOpen && editableUserId && (
        <EditUserModal
          editId={editableUserId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Sr No.</th>
            <th style={styles.th}>Role</th>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Email</th>
            <th style={styles.th}>Phone</th>
            <th style={styles.th}>Action</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map((user, index) => (
            <tr key={user._id}>
              <td style={styles.td}>{index + 1}</td>
              <td style={styles.td}>{user.role}</td>
              <td style={styles.td}>
                {user.firstName} {user.lastName}
              </td>
              <td style={styles.td}>{user.email}</td>
              <td style={styles.td}>{user.phone}</td>
              <td style={styles.td}>
                <button
                  className=" tw-bg-accent hover:tw-bg-secondary tw-text-primary tw-px-3 tw-py-2 tw-rounded-full"
                  onClick={() => {
                    setEditableUserId(user._id);
                    setIsOpen(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className={`tw-bg-red-300 hover:tw-bg-red tw-text-black tw-px-3 tw-py-2 tw-rounded-full tw-ml-2
                   ${user.role === "superAdmin" ? "tw-cursor-not-allowed  tw-opacity-30" : ""}
                  `}
                  onClick={() => handleDelete(user._id)}
                  disabled={user.role === "superAdmin"}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationComponent itemsPerPage={10}
        totalItems={allUsers.length}
        handlePageChange={handlePageChange}
        currentPage={currentPage} />
    </div>
  );
};

const EditUserModal = ({ editId, isOpen, setIsOpen }) => {
  const { isLoggedIn, userDetails } = useAuth();
  const [editUserDetails, SetEditUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const authToken = getCookieValue("authToken");
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };

        const res = await axios.get(
          `${BASE_ADMIN_URL}/getUserById/${editId}`,
          config,
        );
        const { firstName, lastName, email, phone, dob, role } = res.data;
        const dobString = dob ? dob.split("T")[0] : "";
        SetEditUserDetails({
          firstName,
          lastName,
          email,
          phone,
          dob: dobString,
          role,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserDetails();
  }, [editId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    SetEditUserDetails({
      ...editUserDetails,
      [name]: value,
    });
  };

  const handlePhone = (value) => {
    console.log(value);
    SetEditUserDetails((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSave = async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.put(
        `${BASE_ADMIN_URL}/editUser/${editId}`,
        editUserDetails,
        config,
      );
      toast.success(res.data.message);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customModalStyles}
      contentLabel="Edit User Modal"
    >
      <div className="tw-bg-white tw-border tw-border-4 tw-p-8 tw-rounded-lg tw-h-full ftw-lex tw-flex-col">
        <h1 className="tw-text-2xl tw-font-bold tw-mb-4 tw-mx-auto">
          User Profile
        </h1>
        <div className="tw-mb-4">
          <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
           Role 
          </label>
          <input
            type="text"
            name="role"
            value={editUserDetails.role}
            onChange={handleInputChange}
            readOnly={userDetails.role !== "superAdmin"? true : false}
            className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-w-full"
          />
        </div>
        <div className="tw-mb-4">
          <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            value={editUserDetails.firstName}
            onChange={handleInputChange}
            className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-w-full"
          />
        </div>
        <div className="tw-mb-4">
          <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            value={editUserDetails.lastName}
            onChange={handleInputChange}
            className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-w-full"
          />
        </div>
        <div className="tw-mb-4">
          <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={editUserDetails.email}
            onChange={handleInputChange}
            className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-w-full"
          />
        </div>
        <div className="tw-mb-4">
          <label className="tw-block tw-text-gray-700 tw-font-bold mb-2">
            Phone
          </label>
          <PhoneInput
            id="phone"
            name="phone"
            defaultCountry="IN"
            placeholder="Phone"
            value={editUserDetails.phone}
            onChange={(phone) => handlePhone(phone)}
            className="tw-mt-1 tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-w-full"
          />
        </div>
        <div className="tw-mb-4">
          <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
            Date of Birth
          </label>
          <input
            type="date"
            name="dob"
            format="yyyy-mm-dd"
            value={editUserDetails.dob}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="tw-flex tw-flex-row tw-w-full">
          <button
            onClick={handleCancel}
            className="tw-w-1/2 tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="tw-bg-green-500 tw-w-1/2 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ViewAllUsers;
