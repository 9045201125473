import { useEffect } from "react";

const useGoogleAnalytics = () => {
  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-H93V6SKTJ7";
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-H93V6SKTJ7");

    // Cleanup function
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Function to track page views
  const trackPageView = (path) => {
    if (window.gtag) {
      window.gtag("config", "G-H93V6SKTJ7", { page_path: path });
    }
  };

  return { trackPageView };
};

export default useGoogleAnalytics;
