import L from "leaflet";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = () => {
  const position = [17.427917467360952, 78.41446648468064];
  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ width: "100%", height: "400px", zIndex: 0 }}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          <Link
            to="https://maps.app.goo.gl/VcPG55KRR5Ekyq6U9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Locate Us on Google Maps
          </Link>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
