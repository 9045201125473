import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsSend } from "react-icons/bs";
import { FaMapLocationDot, FaPhone } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";
import { BASE_USER_URL } from "../../utils/APIURLs";
import { isValidEmail, isValidPhone } from "../../utils/validation";
import Map from "./Map";

const ContactPage = () => {
  const { title } = useParams();
  const { allCourses, isLoggedIn, userDetails } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    courseName: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (title) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        courseName: title,
      }));
    }
  }, [title]);

  useEffect(() => {
    if (isLoggedIn) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: userDetails.firstName + " " + userDetails.lastName,
        email: userDetails.email,
        phone: userDetails.phone || "",
      }));
    }
  }, [isLoggedIn, userDetails]);

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const validateForm = () => {
    if (isLoggedIn) return true; 

    if (!formData.name.trim()) {
      toast.error("Please provide your name.");
      return false;
    }
    if (!formData.email.trim() || !isValidEmail(formData.email)) {
      toast.error("Please provide a valid email address.");
      return false;
    }
    if (!formData.phone.trim() || !isValidPhone(formData.phone)) {
      toast.error("Please provide a valid phone number.");
      return false;
    }
    return true;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      const res = await axios.post(`${BASE_USER_URL}/sendQueryMessage`, {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        course: formData.courseName,
      });
      
      if (res.status === 200) {
        const msg = res.data.message;
        toast.success(msg);
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          courseName: "",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to send message. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-mt-16">
      <h1 className="tw-font-bold tw-text-primary tw-text-center">
        Contact Us
      </h1>
      <div className="tw-container tw-mx-auto md:tw-p-16 tw-px-8 tw-flex tw-flex-col-reverse tw-justify-center md:tw-flex-row tw-flex-col">
        <div className="tw-flex tw-flex-col md:tw-w-2/5 md:tw-mt-0 tw-mt-8">
          <Map />
          <p className="tw-text-lg">
            If you have any questions about our programs please get in touch
            using the form and a member of our team will contact you.
          </p>
          <div className="tw-flex md:tw-flex-row tw-flex-col">
            <div className="tw-p-4">
              <div className="tw-flex tw-flex-row tw-space-x-2">
                <FaPhone className="tw-text-xl tw-text-accent" />
                <p className="tw-text-2xl">Telephone</p>
              </div>
              <p className="tw-text-xl md:tw-mx-auto">+91 6305738473</p>
            </div>
            <div className="tw-p-4">
              <div className="tw-flex tw-flex-row tw-space-x-2">
                <FaMapLocationDot className="tw-text-xl tw-text-accent" />
                <p className="tw-text-2xl">Address</p>
              </div>
              <p className="tw-text-xl">
                H No 8-2-293/82/a/231, Road No-36, Metro Pillar No 1608, Jubilee
                Hills, Hyderabad, Telangana, India, 500033
              </p>
            </div>
          </div>
        </div>
        <div
          className="md:tw-w-1/2 tw-w-full md:tw-pl-8 md:tw-order-2 tw-order-1"
          data-aos="fade-up"
        >
          <hr className="md:tw-hidden tw-border-2 tw-border-accent tw-mb-4" />
          <h2 className="tw-text-center tw-text-3xl tw-font-bold tw-text-primary tw-mb-4 tw-whitespace-nowrap">
            Send Your Message
          </h2>
          <>
            {!isLoggedIn && (
              <div>
                <p className="tw-text-primary tw-text-md tw-font-medium tw-mb-4">
                  Already have an account? &nbsp;
                  <span
                    className="tw-text-secondary tw-cursor-pointer tw-underline"
                    onClick={() => {
                      sessionStorage.setItem("prevPage", window.location.href);
                      console.log(
                        "prevPage",
                        sessionStorage.getItem("prevPage"),
                      );
                      window.location.href = "/login";
                    }}
                  >
                    Login
                  </span>
                </p>
              </div>
            )}
          </>
          <form onSubmit={handleSendMessage}>
            <div className="tw-mb-4">
              <label
                htmlFor="name"
                className="tw-block tw-text-sm tw-font-medium tw-text-primary"
              >
                Name*
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-w-full tw-border tw-rounded-md tw-border-2 tw-border-accent"
                required 
                disabled={isLoggedIn}
              />
            </div>
            <div className="tw-mb-4">
              <label
                htmlFor="email"
                className="tw-block tw-text-sm tw-font-medium tw-text-primary"
              >
                Email*
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-w-full tw-border tw-rounded-md tw-border-2 tw-border-accent"
                required 
                disabled={isLoggedIn}
              />
            </div>
            <div className="tw-mb-4">
              <label
                htmlFor="phone"
                className="tw-block tw-text-sm tw-font-medium tw-text-primary"
              >
                Phone No.*
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-w-full tw-border tw-rounded-md tw-border-2 tw-border-accent"
                required 
                disabled={isLoggedIn}
              />
            </div>
            <div className="tw-mb-4">
              <label
                htmlFor="courseName"
                className="tw-block tw-text-md tw-font-medium tw-text-primary"
              >
                I'm interested in
              </label>
              <select
                id="courseName"
                name="courseName"
                value={formData.courseName}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full tw-border-2 tw-border-accent"
              >
                <option value="">Select a course</option>
                {allCourses.map((course) => (
                  <option key={course._id} value={course.title}>
                    {course.title} {course.subtitle}
                  </option>
                ))}
                <option value="General">Something Else</option>
              </select>
            </div>
            <div className="tw-mb-4">
              <label
                htmlFor="message"
                className="tw-block tw-text-md tw-font-medium tw-text-primary"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-w-full tw-border tw-rounded-md tw-border-2 tw-border-accent"
              ></textarea>
            </div>
            <div className="tw-flex tw-justify-center">
              <button
                type="submit"
                className="tw-bg-accent tw-font-bold tw-text-primary md:tw-w-1/3 tw-w-1/2 tw-px-4 tw-py-2 tw-rounded-full tw-border
                tw-border-2 tw-border-primary hover:tw-border-accent"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Sending...</span>
                ) : (
                  <>
                    Send
                    <BsSend className="tw-inline tw-ml-2" />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      {isLoading && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
          <div className="tw-bg-white tw-p-4 tw-rounded-md">
            <p className="tw-text-primary">Sending message...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactPage;