import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { FaShoppingCart } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { useAuth } from "../AuthContext";
import AdminMenuSidebar from "../admin/components/AdminMenuSidebar";
import "./styles/MenuNavbar.css";

const MenuSideBar = ({ role }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isLoggedIn, categories, cartSize, logout, allCourses, userRole, courseMapping } = useAuth();
  const [courseDropdownOpen, setCourseDropdownOpen] = useState(false);
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [demoClassDropdownOpen, setDemoClassDropdownOpen] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState("");
  const [hoveredCourses, setHoveredCourses] = useState({});
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleCourseDropdown = () => {
    setCourseDropdownOpen(!courseDropdownOpen);
    // if (aboutDropdownOpen) setAboutDropdownOpen(false);
    // if (demoClassDropdownOpen) setDemoClassDropdownOpen(false);
  };

  const toggleAboutDropdown = () => {
    setAboutDropdownOpen(!aboutDropdownOpen);
    if (courseDropdownOpen) setCourseDropdownOpen(false);
    if (demoClassDropdownOpen) setDemoClassDropdownOpen(false);
  };

  const toggleDemoClassDropdown = () => {
    setDemoClassDropdownOpen(!demoClassDropdownOpen);
    if (courseDropdownOpen) setCourseDropdownOpen(false);
  };

  useEffect(() => {
    if (hoveredCategory !== "") {
      const hoverCourses = allCourses.filter(
        (entity) => entity.courseCategory._id === hoveredCategory,
      );
      hoverCourses.sort((a, b) => {
        return a.order - b.order;
      })
      const tempCourseMapping = {}
      hoverCourses.forEach((course) => {
        tempCourseMapping[course.title] = courseMapping[course.title]
      })
      setHoveredCourses(tempCourseMapping);
    }
  }, [hoveredCategory, allCourses, courseMapping]);

  return (
    <>
      <div
        className={`tw-container `}
        style={{ top: menuOpen ? "-100%" : 0, transition: "top 0.8s ease" }}
      >
        <div className="tw-bg-lightest tw-border-primary tw-text-primary tw-fixed tw-w-full tw-top-0 tw-p-8 tw-px-4 md:tw-px-32 tw-flex tw-justify-between tw-items-center tw-z-10 tw-h-20">
          {/* Responsive Menu Icon */}
          <div className="md:tw-hidden tw-cursor-pointer">
            <CiMenuBurger
              className="tw-w-6 tw-h-6 tw-text-primary"
              onClick={toggleMenu}
            />
          </div>
          <div className="tw-flex tw-items-center">
            <img
              src="https://images.ctfassets.net/fxuhbm32k7qv/6CspbnrJcrlKkMwEOl24ec/127b6f12d494886c7a4e13178665122e/White_BG_200_200.png"
              alt="100FoldsAcademy"
              className="tw-w-16 tw-h-16 tw-ml-16 tw-mt-1 tw-cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
      <div className={`menu-sidebar ${menuOpen ? "open" : ""}`}>
        {role === "admin" || role === "superAdmin" ? (
          <AdminMenuSidebar toggleMenu={toggleMenu} />
        ) : (
          <div className="menu-sidebar-content tw-flex tw-flex-col  tw-space-y-4">
            {/* About Drodown */}
            <div className="menu-items tw-relative ">
              <div
                className="tw-flex tw-items-center tw-cursor-pointer"
                onClick={toggleAboutDropdown}
              >
                About Us
                <div className="tw-ml-5 ">
                  {aboutDropdownOpen ? (
                    <IoIosArrowDropupCircle className="tw-w-6 tw-h-6 tw-cursor-pointer" />
                  ) : (
                    <IoIosArrowDropdownCircle className="tw-w-6 tw-h-6 tw-cursor-pointer" />
                  )}
                </div>
              </div>
              {aboutDropdownOpen && (
                <div
                  className={`menu-dropdown tw-transform tw-transition ${aboutDropdownOpen ? "open" : ""}`}
                >
                  <div
                    className="hover:tw-bg-accent hover:tw-text-primary  tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full"
                    onClick={() => {
                      navigate("/about-academy");
                      toggleMenu();
                    }}
                  >
                    The 100FoldsAcademy
                  </div>
                  <div
                    className="hover:tw-bg-accent hover:tw-text-primary tw-cursor-pointer tw-pl-4 tw-py-2 tw-w-full "
                    onClick={() => {
                      navigate("/about-academy");
                      const checkSectionExistence = setInterval(() => {
                        const sectionElement = document.getElementById("our-vision-mission");
                        if (sectionElement) {
                          clearInterval(checkSectionExistence);
                          sectionElement.scrollIntoView({ behavior: "smooth" });
                          toggleMenu();
                        }
                      }, 100);
                    }}
                  >
                    Our Vision & Mission
                  </div>

                  <div
                    className="hover:tw-bg-accent hover:tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full"
                    onClick={() => {
                      navigate("/chefs");
                      toggleMenu();
                    }}
                  >
                    Our Faculty
                  </div>
                </div>
              )}
            </div>
            {/* Demo Class Dropdown */}
            {/* <div className="menu-items tw-relative">
              <div className="tw-flex tw-items-center tw-cursor-pointer">
                Tours & Demo Classes
                <div className="tw-ml-5">
                  {demoClassDropdownOpen ? (
                    <IoIosArrowDropupCircle
                      className="tw-w-6 tw-h-6 tw-cursor-pointer"
                      onClick={toggleDemoClassDropdown}
                    />
                  ) : (
                    <IoIosArrowDropdownCircle
                      className="tw-w-6 tw-h-6 tw-cursor-pointer"
                      onClick={toggleDemoClassDropdown}
                    />
                  )}
                </div>
              </div>
              {demoClassDropdownOpen && (
                <div
                  className={`menu-dropdown tw-transform tw-transition ${demoClassDropdownOpen ? "open" : ""}`}
                >
                  <div
                    className="hover:tw-bg-accent hover:tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full"
                    onClick={() => {
                      navigate("/onsite-tour");
                      toggleMenu();
                    }}
                  >
                    Tours
                  </div>
                  <div
                    className="hover:tw-bg-accent hover:tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full"
                    onClick={() => {
                      navigate("/onsite-demo");
                      toggleMenu();
                    }}
                  >
                    Demo Classes
                  </div>
                </div>
              )}
            </div> */}
            {!isLoggedIn ? (
              <>
                <div
                  className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
                  onClick={() => {
                    navigate("/login");
                    toggleMenu();
                  }}
                >
                  Login
                </div>
                <div
                  className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
                  onClick={() => {
                    navigate("/register");
                    toggleMenu();
                  }}
                >
                  Sign Up 
                </div>
              </>
            ) : (
              <div
                className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
                onClick={() => {
                  logout();
                  toggleMenu();
                }}
              >
                Logout
              </div>
            )}
            <div
              className=" menu-items tw-flex tw-items-center tw-relative tw-cursor-pointer"
              onClick={() => {
                navigate("/cart");
                toggleMenu();
              }}
            >
              Cart
              <FaShoppingCart className=" tw-w-6 tw-h-6 tw-text-primary tw-text-white " />
              <div className="tw-relative tw-top-0 tw-right-2 tw--mt-4 tw--mr-1 tw-bg-secondary tw-text-white tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full">
                {cartSize}
              </div>
            </div>

            {/* Courses dropdown */}
            <div className="menu-items tw-relative">
              <div className="tw-flex tw-flex-row ">
                <div
                  className="tw-flex tw-items-center tw-cursor-pointer"
                  onClick={() => {
                    navigate("/courses");
                    toggleMenu();
                  }}
                >
                  Explore Our Courses
                </div>
                <div
                  className="tw-ml-3 tw-cursor-pointer"
                  onClick={toggleCourseDropdown}
                >
                  {courseDropdownOpen ? (
                    <IoIosArrowDropupCircle className="tw-w-6 tw-h-6 tw-cursor-pointer" />
                  ) : (
                    <IoIosArrowDropdownCircle className="tw-w-6 tw-h-6 tw-cursor-pointer" />
                  )}
                </div>
              </div>
              {/* Additional courses content */}
              {courseDropdownOpen && (
                <div className="courses-dropdown">
                  {categories.map((category) => (
                    <div
                      key={category._id}
                      className=" tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-left "
                      onClick={() => {
                        navigate(`/courses/category/${category._id}`);
                        setCourseDropdownOpen(false);
                        toggleMenu();
                      }}
                      onMouseEnter={() =>
                        setHoveredCategory(category._id)
                      }
                      onMouseLeave={() => setHoveredCategory(null)}
                    >
                      {category.title}
                      {hoveredCategory === category._id && (
                        <div
                          className="tw-flex tw-flex-col tw-text-left tw-px-4 tw-py-2 tw-w-full t"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="tw-text-right">
                            {Object.entries(hoveredCourses).map(([title, course]) => (
                              <div
                                key={title}
                                className="tw-text-sm tw-cursor-pointer tw-text-left  tw-p-2 tw-bg-transparent hover:tw-bg-accent hover:tw-text-primary"
                                onClick={() => {
                                  if (hoveredCourses[title].length === 1) {
                                    navigate(`/courses/${title}`)
                                  } else {
                                    navigate(`/courses/title/${title}`)
                                  }
                                  setCourseDropdownOpen(false);
                                  toggleMenu();
                                }}
                              >
                                {title}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
              onClick={() => {
                navigate("/contact-us");
                toggleMenu();
              }}
            >
              Contact Us
            </div>
            {/* <div
              className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
              onClick={() => {
                navigate("/blogs");
                toggleMenu();
              }}
            >
              Blogs
            </div> */}

            {isLoggedIn && (
              <div
                className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
                onClick={() => {
                  navigate("/profile");
                  toggleMenu();
                }}
              >
                Profile
              </div>
            )}
            <div
              className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
              onClick={() => {
                navigate("/news-events");
                toggleMenu();
              }}
            >
              News & Updates
            </div>
            {/* <div
              className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
              onClick={() => {
                navigate("/gallery");
                toggleMenu();
              }}
            >
              Gallery
            </div> */}
            <div
              className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
              onClick={() => {
                navigate("/faq");
                toggleMenu();
              }}
            >
              Faqs
            </div>
            <div
              className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
              onClick={() => {
                navigate("/policies");
                toggleMenu();
              }}
            >
              Policies
            </div>
          </div>
        )}
        <FaTimes className="close-icon" onClick={toggleMenu} />
      </div >
    </>
  );
};

export default MenuSideBar;
