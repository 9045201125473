import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useQuery, gql } from "@apollo/client";
import { toast } from "react-toastify";
import { BASE_USER_URL, BASE_ENTITY_URL } from "./utils/APIURLs";
import { getCookieValue, removeCookie } from "./utils/manageCookie";
import ENTITY_DETAILS_QUERY from "./utils/queries/entityDetailsQuery";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cartSize, setCartSize] = useState(0);
  const [userRole, setUserRole] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [allEntities, setAllEntities] = useState([]);
  const [courseMapping, setCourseMapping] = useState({}); 
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    dob: "",
    phone: "",
    cart: [],
  });
  const [cartItems, setCartItems] = useState({
    courses: [],
    demoClass: [],
    recipes: [],
  });
  const [boughtItems, setBoughtItems] = useState({
    courses: [],
    demoClass: [],
    recipes: [],
  });

  const { data } = useQuery(gql`
        query{
          ${ENTITY_DETAILS_QUERY}
        }
    `);

  const fetchCourseDetailsFromCMS = async () => {
    try {
      if (allCourses) {
        const tempCourses = [...allCourses];
        tempCourses.forEach((course) => {
          const courseDetails = data.entityDetailsCollection.items.find(
            (item) => item.entityId === course._id,
          );
          if (courseDetails) {
            course.faqs = courseDetails.faqs;
            course.entityImage = courseDetails?.entityImage?.url;
            course.extraInfo = courseDetails.entityExtraInfo;
            course.order = courseDetails.order;
          }
        });
        setAllCourses(tempCourses);
      }
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  const fetchUserDetails = async (authToken) => {
    try {
      const response = await axios.get(`${BASE_USER_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const { firstName, lastName, email, dob, phone, cart, role } =
        response.data;
      const dobString = dob ? dob.slice(0, 10) : "";
      setUserDetails({
        firstName,
        lastName,
        email,
        dob: dobString,
        phone,
        cart,
        role,
      });
      setCartSize(cart.length);
      setUserRole(role);
    } catch (error) {
      console.log(error);
    }
  };

  const addCartItemsFromLocalStorage = async (authToken) => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (cartItems) {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      for (const item of cartItems) {
        try {
          await axios.post(`${BASE_USER_URL}/addEntityToCart`, item, config);
          console.log("Item added to cart successfully.");
        } catch (error) {
          console.log("Error adding item to cart:", error);
        }
      }
      localStorage.removeItem("cartItems");
    }
  };
  const login = async (credentials) => {
    try {
      const response = await axios.post(`${BASE_USER_URL}/login`, credentials);
      const authToken = response.data.token;
      document.cookie = `authToken=${authToken}`;
      document.cookie = `role=${response.data.role}`;
      setUserRole(response.data.role);
      setIsLoggedIn(true);
      addCartItemsFromLocalStorage(authToken);
      fetchUserDetails(authToken);
      toast.success("Logged in successfully");
    } catch (error) {
      toast.error("Invalid credentials");
    }
  };

  const logout = () => {
    removeCookie("role");
    removeCookie("authToken");
    sessionStorage.clear();
    console.log("Cookie removed successfully.");
    setIsLoggedIn(false);
    setUserRole("");
    setCartSize(0);
    setCartItems({ courses: [], demoClass: [], recipes: [] });
  };

  const updateCartSize = (newSize) => {
    setCartSize(newSize);
  };

  const updateUserDetails = (newDetails) => {
    setUserDetails(newDetails);
  };

  const fetchAllEntities = async () => {
    try {
      const response = await axios.get(`${BASE_ENTITY_URL}`);
      const { courses, demoClass, recipes } = response.data;
      // fetch only upcoming time slots 
      courses.forEach((course) => {
        course.upComingSlots = course.timeSlots.filter(
          (slot) => new Date(slot.slot.startDate) >= new Date(),
        );
      });
      setAllEntities([...courses, ...demoClass, ...recipes]);
      setAllCourses(courses);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(`${BASE_ENTITY_URL}/courses/categories`);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCartItems = async () => {
    try {
      const authToken = getCookieValue("authToken");
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (isLoggedIn && authToken) {
        const res = await axios.get(`${BASE_USER_URL}/getCart`, config);
        const { courses, demoClass, recipes } = res.data;
        if (courses) {
          courses.forEach((course) => {
            const courseDetails = data.entityDetailsCollection.items.find(
              (item) => item.entityId === course._id,
            );
            if (courseDetails) {
              course.faqs = courseDetails.faqs;
              course.entityImage = courseDetails.entityImage.url;
            }
          });
        }
        setCartItems({ courses, demoClass, recipes });
        setCartSize(courses.length + demoClass.length + recipes.length);
      } else {
        const cart = JSON.parse(localStorage.getItem("cartItems"));
        if (cart.length === 0) {
          setCartItems({ courses: [], demoClass: [], recipes: [] });
          setCartSize(0);
          return;
        }
        const res = await axios.post(
          `${BASE_USER_URL}/getCartForNonLoggedInUser`,
          { cart },
        );
        const { courses, demoClass, recipes } = res.data;
        if (courses) {
          courses.forEach((course) => {
            const courseDetails = data.entityDetailsCollection.items.find(
              (item) => item.entityId === course._id,
            );
            if (courseDetails) {
              course.faqs = courseDetails.faqs;
              course.entityImage = courseDetails.entityImage.url;
            }
          });
        }
        setCartItems({ courses, demoClass, recipes });
        setCartSize(courses.length + demoClass.length + recipes.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBoughtItems = async () => {
    try {
      const authToken = getCookieValue("authToken");
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await axios.get(`${BASE_USER_URL}/getBoughtEntities`, config);
      const { courses, demoClass, recipes } = res.data;
      setBoughtItems({ courses: courses, demoClass, recipes });
      console.log(courses);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const authToken = getCookieValue("authToken");
    if (authToken) {
      setIsLoggedIn(true);
      fetchUserDetails(authToken);
      addCartItemsFromLocalStorage(authToken);
      fetchUserDetails(authToken);
      getCartItems();
      fetchBoughtItems();
    }
    getCategories();
    fetchAllEntities();
  }, []);

  const makeCourseMapping = () => {
    const tempCourseMapping = {};
    allCourses.forEach((course) => {
      if (tempCourseMapping[course.title]) {
        tempCourseMapping[course.title].push(course);
      }
      else {
        tempCourseMapping[course.title] = [course];
      }
    });
    setCourseMapping(tempCourseMapping);
  };

  useEffect(() => {
    fetchCourseDetailsFromCMS();
  }, [allEntities]);

  useEffect(() => {
    makeCourseMapping();
  }, [allCourses]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        allCourses,
        login,
        logout,
        categories,
        userDetails,
        updateUserDetails,
        cartSize,
        updateCartSize,
        setIsLoggedIn,
        fetchUserDetails,
        userRole,
        getCategories,
        allEntities,
        fetchAllEntities,
        cartItems,
        getCartItems,
        courseMapping,
        boughtItems,
        fetchBoughtItems,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
