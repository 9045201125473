import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import axios from "axios";
import {
  BASE_ENTITY_URL,
  BASE_USER_URL,
  BASE_ADMIN_URL,
} from "../../utils/APIURLs";
import { useNavigate } from "react-router-dom";
import { formattedDate } from "../../utils/helpfulFunctions";
import PaginationComponent from "../../components/PaginationComponent";
import styles from "../../components/styles/TableStyles";

const SortableTableHeader = ({ columnName, sortFunction, sortName }) => {
  const [isAscending, setIsAscending] = useState(true);

  const toggleSortOrder = () => {
    setIsAscending(!isAscending);
    sortFunction(sortName, isAscending ? "desc" : "asc");
  };
  return (
    <th>
      <div
        className="tw-flex tw-flex-row tw-justify-between"
        style={styles.th}
        onClick={toggleSortOrder}
      >
        <span>{columnName}</span>
        {isAscending ? <GoTriangleDown /> : <GoTriangleUp />}
      </div>
    </th>
  );
};

const ViewIndividualDetails = ({ categories, allEntities }) => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [filteredEntities, setFilteredEntities] = useState([]);
  const navigate = useNavigate();

  const getFilteredEntities = () => {
    let filtered = allEntities;
    console.log(selectedType, selectedCategory, selectedEntity, selectedSlot);
    if (selectedType) {
      filtered = filtered.filter((entity) => entity.itemType === selectedType);
    }
    if (selectedCategory) {
      filtered = filtered.filter(
        (entity) => entity.courseCategory.title === selectedCategory,
      );
    }
    if (selectedEntity) {
      filtered = filtered.filter((entity) => entity.id === selectedEntity);
    }
    setFilteredEntities(prevFiltered => [...filtered]);
  };

  useEffect(() => {
    getFilteredEntities();
  }, [selectedType, selectedCategory, selectedEntity, selectedSlot]);
  return (
    <>
      <h1 className="tw-text-center tw-my-5">View Individual Details </h1>
      <div className="tw-flex tw-space-y-3 tw-mb-4 tw-flex-col tw-items-center">
        <div>
          <label htmlFor="type" className="tw-w-24 tw-px-2">
            Type:
          </label>
          <select
            id="type"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className="tw-ml-2 tw-p-2 tw-bg-lightest tw-w-64"
          >
            <option value="">Select Type</option>
            <option value="course">Course</option>
            <option value="recipe">Recipe</option>
            <option value="demoClass">Demo Class</option>
          </select>
        </div>
        {selectedType === "course" && (
          <div>
            <label htmlFor="category" className="tw-w-24 tw-px-2">
              Category:
            </label>
            <select
              id="category"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="tw-ml-2 tw-p-2 tw-bg-lightest tw-w-64"
            >
              <option value="">Select Category</option>
              {categories.map((category, index) => (
                <option key={index} value={category.title}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* Entity selection */}
        <div>
          <label htmlFor="entity" className="tw-w-24 tw-px-2">
            Entity:
          </label>
          <select
            id="entity"
            value={selectedEntity}
            onChange={(e) => setSelectedEntity(e.target.value)}
            className="tw-ml-2 tw-p-2 tw-bg-lightest tw-w-64"
          >
            <option value="">Select Entity</option>
            {filteredEntities.map((entity, index) => (
              <option key={index} value={entity.id}>
                {entity.title} {entity.subtitle}
              </option>
            ))}
          </select>
        </div>
        {/* Slot selection */}
        {selectedType !== "recipe" && (
          <div>
            <label htmlFor="slot" className="tw-w-24 tw-px-2">
              Slot:
            </label>
            <select
              id="slot"
              value={selectedSlot}
              onChange={(e) => setSelectedSlot(e.target.value)}
              className="tw-ml-2 tw-p-2 tw-bg-lightest tw-w-64"
            >
              <option value="">Select Slot</option>
              {filteredEntities.map((entity, index) =>
                entity.timeSlots.map((slot, index) => (
                  <option key={index} value={slot._id}>
                    {formattedDate(slot.slot.startDate)} -{" "}
                    {formattedDate(slot.slot.endDate)}
                  </option>
                )),
              )}
            </select>
          </div>
        )}

        <button
          className="tw-p-2 tw-bg-primary tw-text-white tw-rounded-md"
          onClick={() => {
            navigate(
              `/admin/viewEnrolledUsers/${selectedEntity}/${selectedSlot}`,
            );
          }}
          disabled={
            !selectedEntity ||
            !selectedSlot ||
            (selectedType === "course" &&
              (!selectedCategory || !selectedEntity))
          }
        >
          View Enrolled Users
        </button>
      </div>
    </>
  );
};

const ViewEnrollmentDetails = () => {
  const [entities, setEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const { categories, allEntities } = useAuth();
  const [allUniqueNames, setAllUniqueNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState({
    itemType: "all",
    title: "all",
    courseCategory: "all",
  });

  const handleChangeFilterValue = (e) => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const sortTableData = (sortName, sortOrder) => {
    const sorted = filteredEntities.slice().sort((a, b) => {
      if (sortName === "title") {
        let aValue = a[sortName] + a["subtitle"];
        let bValue = b[sortName] + b["subtitle"];
        if (sortOrder === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      } else {
        let aValue = a[sortName];
        let bValue = b[sortName];
        if (sortOrder === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      }
    });

    console.log(sorted)

    setFilteredEntities(prevFiltered => [...sorted])
  };

  const handleFilterChange = () => {
    let filtered = allEntities;
    if (filterValues.itemType !== "all") {
      filtered = filtered.filter(
        (entity) => entity.itemType === filterValues.itemType,
      );
    }
    if (filterValues.title !== "all") {
      // if any subtitle is present, then filter by title and subtitle
      filtered = filtered.filter(
        (entity) => {
          const newStr = entity.title + entity.subtitle;
          return newStr.includes(filterValues.title);
        }
      );
    }
    if (filterValues.courseCategory !== "all") {
      filtered = filtered.filter((entity) => {
        if (entity.courseCategory) {
          return entity.courseCategory.title === filterValues.courseCategory;
        }
      });
    }
    setFilteredEntities(expandEntities(filtered));
  };

  const expandEntities = (entities) => {
    const expanded = [];

    entities.forEach((entity) => {
      entity.timeSlots.forEach((item, index) => {
        const expandedEntity = {
          ...entity,
          slotId: item._id,
          startDate: item.slot.startDate,
          endDate: item.slot.endDate,
          totalSeats: item.totalSeats,
          enrolledSeats: item.totalSeats - item.remainingSeats,
        };
        expanded.push(expandedEntity);
      });
    });
    return expanded;
  };

  useEffect(() => {
    handleFilterChange();
  }, [filterValues]);

  const getAllItems = () => {
    const uniqueNames = allEntities.map((entity) => {
      return {
        title: entity.title,
        subtitle: entity.subtitle,
        id: entity._id,
        itemType: entity.itemType,
        courseCategory: entity.courseCategory,
        timeSlots: entity.timeSlots,
      };
    });
    setAllUniqueNames(uniqueNames);
    const expandedEntities = expandEntities(allEntities);
    setEntities(expandedEntities);
    setFilteredEntities(expandedEntities);
  };

  useEffect(() => {
    getAllItems();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEntities.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  return (
    // make a table to show all the details of the course
    <div className="tw-pt-8 tw-container tw-mx-auto">
      <h1 className="tw-text-center tw-mb-5">Enrollment Details</h1>
      {/* Filtering methods by item type, name and category  */}
      <div className="tw-flex tw-space-x-8 tw-mb-5">
        <div>
          <label htmlFor="itemType">Item Type:</label>
          <select
            name="itemType"
            id="itemType"
            className="tw-ml-2 tw-p-2 tw-bg-lightest tw-w-64"
            onChange={handleChangeFilterValue}
          >
            <option value="all">All</option>
            <option value="course">Course</option>
            <option value="demoClass">Demo Class</option>
            <option value="recipe">Recipe</option>
          </select>
        </div>
        <div>
          <label htmlFor="title">Name:</label>
          <select
            name="title"
            id="title"
            className="tw-ml-2 tw-p-2 tw-bg-lightest tw-w-64"
            onChange={handleChangeFilterValue}
          >
            <option value="all">All</option>
            {allUniqueNames.map((name, index) => (
              <option key={index} value={name.title+name.subtitle}>
                {name.title} {name.subtitle}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="courseCategory">Category:</label>
          <select
            name="courseCategory"
            id="courseCategory"
            className="tw-ml-2 tw-p-2 tw-bg-lightest tw-w-64"
            onChange={handleChangeFilterValue}
          >
            <option value="all">All</option>
            {categories.map((category, index) => (
              <option key={category._id} value={category.title}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
        {/* reset filters */}
        {/* <button
          className="tw-p-2 tw-bg-primary tw-w-24 tw-text-white tw-rounded-md"
          onClick={() => {
            setFilterValues({
              itemType: "all",
              title: "all",
              courseCategory: "all",
            });
          }}
        >
          Reset
        </button> */}
      </div>
      {/* Table to show all the details */}
      <table style={styles.table}>
        <thead>
          <tr>
            <SortableTableHeader columnName="S.No" />
            <SortableTableHeader
              columnName="Item Type"
              sortName="itemType"
              sortFunction={sortTableData}
            />
            <SortableTableHeader
              columnName="Title"
              sortName="title"
              sortFunction={sortTableData}
            />
            <SortableTableHeader
              columnName="Category"
              sortName="courseCategory"
              sortFunction={sortTableData}
            />
            <SortableTableHeader
              columnName="Slot"
              sortName="startDate"
              sortFunction={sortTableData}
            />
            <SortableTableHeader
              columnName="Total Seats"
              sortName="totalSeats"
              sortFunction={sortTableData}
            />
            <SortableTableHeader
              columnName="Enrolled Seats"
              sortName="enrolledSeats"
              sortFunction={sortTableData}
            />
          </tr>
        </thead>
        <tbody>
          {currentItems.map((entity, index) => (
            <tr key={index}>
              <td style={styles.td}>{index + 1}</td>
              <td style={styles.td}>{entity.itemType}</td>
              <td style={styles.td}> {entity.title} {entity.subtitle}</td>
              {entity.itemType === "course" ? (
                <td style={styles.td}>{entity.courseCategory.title}</td>
              ) : (
                <td style={styles.td}>-</td>
              )}
              {entity.itemType !== "recipe" ? (
                <td
                  style={styles.td}
                  className="tw-cursor-pointer tw-underline hover:tw-text-secondary"
                  onClick={() => {
                    navigate(
                      `/admin/viewEnrolledUsers/${entity._id}/${entity.slotId}`,
                    );
                  }}
                >
                  {formattedDate(entity.startDate)} -{" "}
                  {formattedDate(entity.endDate)}
                </td>
              ) : (
                <td style={styles.td}>-</td>
              )}
              <td style={styles.td}>{entity.totalSeats}</td>
              <td style={styles.td}>{entity.enrolledSeats}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        totalItems={filteredEntities.length}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />

      <ViewIndividualDetails
        categories={categories}
        allEntities={allUniqueNames}
      />
    </div>
  );
};

export default ViewEnrollmentDetails;
