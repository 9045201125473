import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

const renderOptions = (links) => {
  const assetMap = new Map();
  if (links && links.assets && links.assets.block) {
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset.url);
    }
  }

  const entryMap = new Map();
  if (links && links.entries && links.entries.inline) {
    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="tw-text-center tw-mb-4">{children}</h1>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h2 className="tw-mb-4 tw-text-secondary">{children}</h2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="tw-text-xl tw-text-primary">{children}</p>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const url = assetMap.get(node.data.target.sys.id);
        return <img src={url} alt={""} className="tw-h-32 tw-w-32" />;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const fields = entryMap[node.data.target.sys.id];
        return <div>{fields.name}</div>;
      },
      [BLOCKS.UL_LIST]: (node, children) => (
        <div className="tw-items-center md:tw-w-2/5 tw-w-[85%] tw-mx-auto tw-text-left">
          <ul className="tw-list-disc tw-text-2xl">{children}</ul>
        </div>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="tw-text-2xl">{children}</li>
      ),
      [BLOCKS.LIST_ITEM_CHILD]: (node, children) => <li>{children}</li>,
      // table rendering
      [BLOCKS.TABLE]: (node, children) => (
        <table className="tw-mb-4">{children}</table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
        // Render the table header cell as a <th> element
        <th className="tw-border-2 tw-border-primary tw-text-center tw-bg-secondary tw-text-white tw-p-2">
          {children}
        </th>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => (
        // Render the table row as a <tr> element
        <tr className="tw-bg-light tw-w-full tw-border-2 tw-border-primary tw-text-center">{children}</tr>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <td className="tw-p-2 tw-border tw-border-2 tw-border-primary ">{children}</td>
      ),
    },
  };
};

const richTextRender = (richText) => {
  return documentToReactComponents(
    richText.json,
    renderOptions(richText.links),
  );
};

export default richTextRender;
