import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import "../../components/styles/MenuNavbar.css";

const AdminMenuSidebar = ({ toggleMenu }) => {
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useAuth();

  return (
    <div className="menu-sidebar-content tw-flex tw-flex-col  tw-space-y-4">
      <div
        className="menu-items tw-cursor-pointer"
        onClick={() => {
          navigate("/admin/dashboard");
          toggleMenu();
        }}
      >
        Dashboard
      </div>
      <div
        className="menu-items tw-cursor-pointer"
        onClick={() => {
          navigate("/admin/courseRegister");
          toggleMenu();
        }}
      >
        Course Enrollment
      </div>
      <div
        className="menu-items tw-cursor-pointer"
        onClick={() => {
          navigate("/register");
          toggleMenu();
        }}
      >
        Student Registration
      </div>

      {!isLoggedIn ? (
        <div
          className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
          onClick={() => {
            navigate("/login");
            toggleMenu();
          }}
        >
          Login
        </div>
      ) : (
        <div
          className="menu-items hover:tw-text-gray-300 tw-cursor-pointer"
          onClick={() => {
            logout();
            toggleMenu();
            navigate("/");
          }}
        >
          Logout
        </div>
      )}
    </div>
  );
};

export default AdminMenuSidebar;
