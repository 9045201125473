import React from "react";
import { Navigate} from "react-router-dom";
import { useAuth } from "./AuthContext";
const ProtectedRoute = ({ role, children }) => {
  const { userRole } = useAuth();
  console.log(userRole);
  if (userRole === "admin" || userRole === "superAdmin") {
    return children;
  }
  return <Navigate to="/" replace />;
};

export default ProtectedRoute;
