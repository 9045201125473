import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import ForgotPassword from "./ForgotPassword";
import { getCookieValue } from "../../utils/manageCookie";
import { toast } from "react-toastify";
import { IoMdEyeOff } from "react-icons/io";
import { IoMdEye } from "react-icons/io";

function Login() {
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [icon, setIcon] = useState("eye-off");
  const [type, setType] = useState("password");

  const handleTogglePassword = () => {
    if (type === "password") {
      setType("text");
      setIcon("eye");
    } else {
      setType("password");
      setIcon("eye-off");
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email && !values.phone) {
      error.email = "Email or Phone is required";
    } else if (values.email && !regex.test(values.email)) {
      error.email = "Email address is invalid";
    } else if (values.phone && !/^[0-9]{10}$/.test(values.phone)) {
      error.phone = "Phone number is invalid";
    }

    if (!values.password) {
      error.password = "Password is required";
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validateForm(formData));
    setIsSubmit(true);
    try {
      if (Object.keys(formErrors).length === 0) {
        await login(formData);
        const role = getCookieValue("role");
        console.log(role);
        const prevPage = sessionStorage.getItem("prevPage");
        if (prevPage) {
          window.location.href = prevPage;
        } else if (role === "admin" || role === "superAdmin") {
          navigate("/admin/dashboard");
        } else if (role === "student") {
          navigate("/");
        } else {
          navigate("/login");
        }
      }
    } catch (error) {
      toast.error("Fill all the fields");
      console.error(error);
    }
  };

  const handleForgotPassword = () => {
    setIsOpen(true);
  };

  return (
    <div className="tw-container tw-mx-auto tw-p-4 tw-mt-16">
      <div className="tw-flex tw-flex-col tw-items-center tw-mt-12">
        <h1 className="tw-text-3xl md:tw-text-5xl tw-font-bold tw-mb-4 tw-text-primary">
          Login to Your Account
        </h1>
        <p className="tw-text-gray-600 tw-mb-4 tw-text-center">
          Please enter your email and password to log in.
        </p>
        <p className="tw-text-gray-600 tw-mb-4 tw-text-center">
          New User?{"  "}
          <button
            type="button"
            onClick={() => navigate("/register")}
            className=" tw-text-secondary tw-font-medium tw-underline"
          >
            Sign Up Here
          </button>
        </p>
      </div>
      <div className="tw-flex tw-justify-center">
        <form
          onSubmit={handleSubmit}
          className="tw-bg-white tw-p-6 tw-rounded tw-shadow-2xl tw-max-w-md tw-w-full"
        >
          <div className="tw-mb-4">
            <label
              htmlFor="email"
              className="tw-block tw-text-md tw-font-medium tw-text-primary"
            >
              Email *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
            />
            {formErrors.email && (
              <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                {formErrors.email}
              </p>
            )}
          </div>
          <div className="tw-mb-4 ">
            <label
              htmlFor="password"
              className="tw-block tw-text-md tw-font-medium tw-text-primary"
            >
              Password *
            </label>
            <div className="tw-relative">
              <input
                type={type}
                id="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
              />
              <span
                onClick={handleTogglePassword}
                className="tw-absolute tw-right-2 tw-top-2 tw-text-primary tw-cursor-pointer"
              >
                {icon === "eye-off" ? (
                  <IoMdEyeOff size={30} />
                ) : (
                  <IoMdEye size={30} />
                )}
              </span>
            </div>
            {formErrors.password && (
              <p className="tw-text-red-500 tw-text-xs tw-mt-1">
                {formErrors.password}
              </p>
            )}
          </div>
          {/* Create account link */}
          {isOpen && <ForgotPassword isOpen={isOpen} setIsOpen={setIsOpen} />}
          <button
            type="submit"
            className="tw-text-primary tw-text-lg  tw-font-bold tw-rounded-full tw-bg-accent tw-border tw-border-4 tw-border-double tw-border-primary tw-p-3 tw-w-full tw-mx-auto tw-my-4 hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-accent"
          >
            Login
          </button>
          <div className="tw-flex tw-flex-col tw-space-y-2 tw-justify-center tw-items-center tw-mb-12">
            {/* Forgot password link */}

            <button
              type="button"
              onClick={handleForgotPassword}
              className="tw-text-primary tw-mb-10  tw-font-medium tw-underline"
            >
              Forgot Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
