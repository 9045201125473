import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_ENTITY_URL } from "../../utils/APIURLs";

const CoursesPage = () => {
  const { categoryId } = useParams();
  const { allCourses } = useAuth();
  const [categoryTitle, setCategoryTitle] = useState("");
  const navigate = useNavigate();

  const courseMapping = {};
  const courseMappingforMetaData = {};

  const filteredCourses = allCourses.filter((course) => {
    return course.courseCategory._id === categoryId;
  });

  filteredCourses.sort((a, b) => {
    return a.order - b.order;
  });

  filteredCourses.forEach((course) => {
    if (courseMapping[course.title]) {
      courseMapping[course.title].push(course);
    } else {
      courseMapping[course.title] = [course];
    }

    if (course.subtitle === "") {
      if (courseMappingforMetaData[course.title]) {
        courseMappingforMetaData[course.title].push({
          description: course.description,
          image: course.entityImage,
        }
        );
      } else {
        courseMappingforMetaData[course.title] = {
          description: course.description,
          image: course.entityImage,
        }
      }
    }
  });

  useEffect(() => {
    axios
      .get(`${BASE_ENTITY_URL}/courses/categories/${categoryId}`)
      .then((res) => {
        const { title } = res.data;
        setCategoryTitle(title);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [categoryId]);

  return (
    <div className="tw-container tw-mx-auto ">
      {/* <SearchBar courses={filteredCourses} categoryTitle={categoryTitle} courseMapping={courseMapping} /> */}
      <div className="tw-flex tw-flex-col tw-items-center " data-aos="fade-up">
        {/* {Object.entries(courseMapping).map(([title, image, ]))} */}
        {Object.entries(courseMappingforMetaData).map(([title, { description, image }]) => (
          <div className="tw-flex md:tw-flex-row tw-flex-col tw-my-8 tw-w-4/5 md:tw-w-2/3 tw-border tw-border-2 tw-rounded-lg tw-shadow-2xl tw-bg-lightest">
            <div
              key={title}
              className="tw-relative tw-bg-gray-300 tw-overflow-hidden tw-shadow-md  md:tw-w-1/3 "
            >
              <img
                src={image}
                alt={title}
                className="tw-w-full tw-h-full tw-object-cover tw-object-center tw-opacity-60"
              />
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mx-4 md:tw-w-2/3">
              <h2 className="tw-text-2xl tw-font-bold tw-text-secondary tw-mb-4 tw-text-center tw-mt-3">
                {title}
              </h2>
              <p className="tw-text-lg tw-font-semi tw-mb-2 tw-text-center">
                {description}
              </p>
              <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-6">
                <button
                  className="tw-bg-accent tw-inline-block tw-border tw-border-double  tw-font-bold  tw-px-4 tw-py-2 tw-rounded-full tw-transition tw-duration-300 hover:tw-bg-secondary tw-text-primary"
                  onClick={() => {
                    if (courseMapping[title].length === 1) {
                      navigate(`/courses/${title}`)
                    } else {
                      navigate(`/courses/title/${title}`)
                    }
                  }}
                >
                  View Details
                </button>
              </div>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default CoursesPage;
