import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { BASE_ADMIN_URL } from "../../../utils/APIURLs";
import { getCookieValue } from "../../../utils/manageCookie";
import { toast } from "react-toastify";
import { IoMdAddCircleOutline } from "react-icons/io";
import styles from "../../../components/styles/TableStyles";
import AddCourseCategory from "../ManageCategory/AddCourseCategory";
import EditCourseCategory from "../ManageCategory/EditCourseCategory";
const ManageCategories = () => {
  const { categories, getCategories } = useAuth();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDelete = async (categoryId) => {
    const authToken = getCookieValue("authToken");
    setIsDeleted(false);
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    console.log(categoryId);
    try {
      const res = await axios.delete(
        `${BASE_ADMIN_URL}/deleteCourseCategory/${categoryId}`,
        config,
      );
      setIsDeleted(true);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete category");
    }
  };

  useEffect(() => {
    getCategories();
  }, [isOpenAdd, isOpenEdit, isDeleted]);
  return (
    <div className="tw-container tw-mx-auto tw-mt-32">
      {isOpenAdd && (
        <AddCourseCategory isOpenAdd={isOpenAdd} setIsOpenAdd={setIsOpenAdd} />
      )}
      {isOpenEdit && (
        <EditCourseCategory
          isOpenEdit={isOpenEdit}
          setIsOpenEdit={setIsOpenEdit}
          categoryDetails={editCategory}
        />
      )}
      <h1 className="tw-text-4xl tw-font-bold tw-text-center tw-mb-8">
        Manage Categories
      </h1>
      <div className="tw-text-right">
        <button onClick={() => setIsOpenAdd(true)}>
          <IoMdAddCircleOutline style={{ fontSize: "32px" }} />
        </button>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Sr No.</th>
            <th style={styles.th}>Category Name</th>
            <th style={styles.th}>Action</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={index}>
              <td style={styles.td}>{index + 1}</td>
              <td style={styles.td}>{category.title}</td>
              <td style={styles.td}>
                <button
                  className="tw-bg-accent hover:tw-bg-secondary tw-text-primary tw-p-2 tw-rounded tw-mr-2"
                  onClick={() => {
                    setIsOpenEdit(true);
                    setEditCategory(category);
                  }}
                >
                  Edit
                </button>
                <button
                  className="tw-bg-red-300 hover:tw-bg-red-500 tw-text-primary tw-p-2 tw-rounded tw-mr-2"
                  onClick={() => handleDelete(category._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageCategories;
