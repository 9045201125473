// BlogDetail.js
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import NEWS_EVENTS_QUERY from "../../utils/queries/newsEventsQuery";
import richTextRender from "../../utils/richTextRender";
const NewsEventsDetail = () => {
  const { newsEventId } = useParams();
  const { loading, error, data } = useQuery(
    gql`
            query {
                ${NEWS_EVENTS_QUERY}
            }`,
  );
  const newsData = data.newsEventsCollection.items.find(
    (news) => news.id.toString() === newsEventId.toString(),
  );

  return (
    <div className="tw-container tw-mx-auto tw-mt-24 md:tw-w-3/5 tw-w-[90%] ">
      <img
        src={newsData.coverImage.url}
        alt={newsData.heading}
        className="tw-w-full tw-h-96 tw-object-cover tw-object-center tw-mb-8"
      />
      <h1 className="tw-text-4xl tw-font-bold tw-mb-4 text-center tw-text-primary">
        {newsData.heading}
      </h1>
      <p className=" tw-mb-8">{richTextRender(newsData.bodyText)}</p>
      {newsData.bodyImagesCollection.items.map((image) => (
        <img
          key={image.url}
          src={image.url}
          alt={newsData.heading}
          className="tw-w-full tw-h-auto tw-mb-8"
        />
      ))}
    </div>
  );
};

export default NewsEventsDetail;
