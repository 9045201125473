import React from "react";

const EmojiStore = [
  {
    name: "happy",
    path: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.b{fill:#864e20}</style></defs><rect x="1" y="1" width="22" height="22" rx="7.656" style="fill:#f8de40" /><path class="b" d="M14 11.207a.32.32 0 0 0-.313.327 2.1 2.1 0 0 1-.5 1.33A1.593 1.593 0 0 1 12 13.3a1.6 1.6 0 0 1-1.187-.43 2.088 2.088 0 0 1-.5-1.334.32.32 0 1 0-.64-.012 2.712 2.712 0 0 0 .679 1.791 2.211 2.211 0 0 0 1.648.623 2.211 2.211 0 0 0 1.647-.626 2.718 2.718 0 0 0 .679-1.791.322.322 0 0 0-.326-.314z" /><path d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344z" style="fill:#e7c930" /><path class="b" d="M9.6 8.833 9.021 8.6a22.797 22.797 0 0 0-2.138-.774 18.44 18.44 0 0 0-1.1-.3h-.012a.246.246 0 0 0-.186.448l.01.006c.325.2.656.392.991.573q.281.15.564.291a.245.245 0 0 1 0 .439q-.285.141-.564.292c-.335.18-.667.369-.992.573l-.016.01a.246.246 0 0 0 .187.447h.018c.374-.088.741-.19 1.105-.3s.723-.234 1.079-.362c.179-.064.355-.134.532-.2l.526-.213.573-.232a.246.246 0 0 0 .002-.465zM18.81 9.844a.182.182 0 0 1-.331.1 2.026 2.026 0 0 0-.568-.567 1.732 1.732 0 0 0-1.916 0 2.016 2.016 0 0 0-.571.569.182.182 0 0 1-.331-.1 1.632 1.632 0 0 1 .346-1.023 1.927 1.927 0 0 1 3.026 0 1.64 1.64 0 0 1 .345 1.021z" /></svg>`,
  },
  {
    name: "sad",
    path: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.b{fill:#864e20}</style></defs><rect x="1" y="1" width="22" height="22" rx="7.656" style="fill:#f8de40"/><path class="b" d="M7.055 7.313A1.747 1.747 0 1 0 8.8 9.059a1.747 1.747 0 0 0-1.745-1.746zM16.958 7.313A1.747 1.747 0 1 0 18.7 9.059a1.747 1.747 0 0 0-1.742-1.746z"/><path d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344z" style="fill:#e7c930"/><path class="b" d="M15.1 13.157a10.752 10.752 0 0 0-.291-1.243 6.685 6.685 0 0 0-.489-1.22 3.624 3.624 0 0 0-.872-1.109 2.354 2.354 0 0 0-.669-.386 2.217 2.217 0 0 0-1.548 0 2.423 2.423 0 0 0-.67.386 3.636 3.636 0 0 0-.872 1.109 6.728 6.728 0 0 0-.49 1.221 10.575 10.575 0 0 0-.29 1.242 16.869 16.869 0 0 0-.228 2.5c0 .438 0 .874.021 1.31a.133.133 0 0 0 .265.012c.062-.428.128-.853.2-1.276.133-.8.3-1.6.507-2.372a9.171 9.171 0 0 1 .84-2.171 2.8 2.8 0 0 1 .661-.8 1.3 1.3 0 0 1 1.654 0 2.812 2.812 0 0 1 .662.8 9.207 9.207 0 0 1 .841 2.172c.206.773.373 1.568.506 2.372.075.421.14.843.2 1.268a.133.133 0 0 0 .264-.012c.021-.433.025-.867.02-1.3a16.979 16.979 0 0 0-.222-2.503z"/></svg>`,
  },
];

const EmotionIcon = ({ name }) => {
  const emo = EmojiStore.find((emoji) => emoji.name === name);
  return (
    <div className="tw-w-32 tw-h-32">
      <div dangerouslySetInnerHTML={{ __html: emo.path }} />
    </div>
  );
};

export default EmotionIcon;
