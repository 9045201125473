import React from "react";
import ImageCarousel from "../../components/ImageCarousel";
import ChefProfiles from "../../components/ChefProfiles";
// import Testimonial from "../../components/Testimonial/Testimonial";
import CourseIntake from "../../components/CourseIntake";

const About = ({ testimonialData }) => {
  return (
    <div >
      <ImageCarousel />
      <div className="tw-container tw-mx-auto tw-p-4 tw-bg-lighter">
        <ChefProfiles />

        <CourseIntake />

        {/* <div className="tw-mx-auto tw-relative">
          <Testimonial testimonialData={testimonialData} />
        </div> */}
      </div>
    </div>
  );
};

export default About;
