import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import image1 from "../images/image_carousal1.png";
//import image1 from "../images/image_carousel1.webp";
//import image2 from "../images/image_carousal2.png";
//import image2 from "../images/image_carousel2.webp";
//import image0 from "../images/image_carousel0.webp";
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";

const ImageCarousel = () => {
  const slides = [
    {
      id: 0,
      //image: image0,
      //image: require("../images/image_carousel0.webp"),
      //srcSet: `${require("../images/image_carousel0_mobile.webp")} 480w, ${require("../images/image_carousel0.webp")} 768w, ${require("../images/image_carousel0.webp")} 1200w`,
      mobileSrc: require("../images/image_carousel0_mobile.webp"),
      desktopSrc: require("../images/image_carousel0.webp"),
    },
    {
      id: 1,
      //image: image1,
      //text: "First Image Text",
      //image: require("../images/image_carousel1.webp"),
      //srcSet: `${require("../images/image_carousel1_mobile.webp")} 480w, ${require("../images/image_carousel1.webp")} 768w, ${require("../images/image_carousel1.webp")} 1200w`,
      mobileSrc: require("../images/image_carousel1_mobile.webp"),
      desktopSrc: require("../images/image_carousel1.webp"),
    },
    {
      id: 2,
      //image: image2,
      //text: "Second Image Text",
      //image: require("../images/image_carousel2.webp"),
      //srcSet: `${require("../images/image_carousel2_mobile.webp")} 480w, ${require("../images/image_carousel2.webp")} 768w, ${require("../images/image_carousel2.webp")} 1200w`,
      mobileSrc: require("../images/image_carousel2_mobile.webp"),
      desktopSrc: require("../images/image_carousel2.webp"),
    },
    // Add more slides as needed
  ];

  React.useEffect(() => {
    // Preload images
    slides.forEach((slide) => {
      //const img = new Image();
      //img.src = slide.image;
      //img.srcSet = slide.srcSet;
      const mobileImg = new Image();
      mobileImg.src = slide.mobileSrc;
      const desktopImg = new Image();
      desktopImg.src = slide.desktopSrc;
    });
  }, [slides]);

  return (
    <>
      <CCarousel controls dark indicators className="tw-bg-gray-300">
        {slides.map((slide) => (
          <CCarouselItem key={slide.id} className="tw-h-[calc(100vh-64px)]">
            <div className="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
              <picture className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
                <source media="(max-width: 767px)" srcSet={slide.mobileSrc} />
                <source media="(min-width: 768px)" srcSet={slide.desktopSrc} />
                <CImage
                  className="tw-max-w-full tw-max-h-full tw-object-contain"
                  src={slide.desktopSrc}
                  //srcSet={slide.srcSet}
                  //sizes="(max-width: 600px) 480px, (max-width: 1200px) 768px, 1200px"
                  alt={`slide ${slide.id}`}
                  loading={slide.id === 0 ? "eager" : "lazy"}
                />
              </picture>
            </div>
          </CCarouselItem>
        ))}
      </CCarousel>
      <div className="tw-bg-accent tw-text-primary tw-text-4xl tw-text-center tw-w-full tw-p-5 tw-mx-auto ">
        <h2 className="">Inspiring Innovation, Creativity & Learning</h2>
      </div>
    </>
  );
};

export default ImageCarousel;
