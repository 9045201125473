import React, { useEffect } from "react";
import { useState } from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import richTextRender from "../../utils/richTextRender";

const Faq = ({ data, type }) => {
  const [expandedId, setExpandedId] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const toggleExpansion = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const courseData = type === "course" && Object.values(data);
  if (courseData) {
    data = courseData.map((faq, index) => {
      return { id: index, ...faq };
    });
  }

  useEffect(() => {
    if (type !== "course") {
      const newData = [...data];
      newData.sort((a, b) => a.id - b.id);
      setSortedData(newData);
    } else {
      setSortedData(data);
    }
  }, []);

  const renderFaqItem = (faq) => {
    return (
      <div key={faq.id}>
        <div
          className="tw-flex tw-items-center tw-cursor-pointer tw-transition  tw-ease-in-out tw-duration-500"
          data-aos="fade-up"
          onClick={() => toggleExpansion(faq.id)}
        >
          <div className="tw-bg-accent tw-text-xl md:tw-text-2xl tw-my-2 tw-rounded-full tw-p-2 md:tw-p-4 tw-transition tw-rotation-180 tw-duration-100 tw-ease-in-out">
            {expandedId === faq.id ? (
              <FaArrowUp className=" tw-animate-bounce" />
            ) : (
              <FaArrowDown />
            )}
          </div>
          <div className="tw-text-xl tw-font-semibold md:tw-text-2xl tw-ml-8 tw-text-primary ">
            {faq.question}
          </div>
        </div>
        <div
          className={`tw-ml-16 tw-whitepace-nowrap tw-transition-all tw-ease-in-out tw-duration-1000 tw-overflow-hidden tw-text-xl  tw-text-primary ${
            expandedId === faq.id ? "tw-max-h-[500px]" : "tw-max-h-0"
          }`}
        >
          {type === "course" ? (
            <div>{faq.answer}</div>
          ) : (
            <div>{richTextRender(faq.answer)}</div>
          )}
        </div>
        <hr className="tw-border tw-border-1 tw-transition tw-duration-500 tw-ease-in-out" />
      </div>
    );
  };

  return (
    <div className="tw-container tw-mx-auto tw-mt-8 tw-p-4">
      {sortedData.map((faq) => renderFaqItem(faq))}
    </div>
  );
};

export default Faq;
