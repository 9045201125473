// This component will appear as a Button and open a Modal if the user is not loggedIn. 
// If the user is already loggedIn the open a Prospectus pdf in new tab.
//
// UseStates -
// UseState {isOpen} is responsible for opening of Modal, Popup of Modal is dependent on {isOpen}.
// UseState {formData} store the values of required fields dynamically.
//
// Functions -
// {handleSubscribe} function check if the user is logged in or not and do conditional routing.
// {handleProspectus} will insure user have filled all the 3 fields of Modal form.
// {handleSubmit} connect the frontend to the backend and send the data stored in UseState {formData}.


import React, { useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { BASE_USER_URL } from "../utils/APIURLs";

const customModalStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "30px",
    transform: "translate(-50%, -50%)",
    minWidth: "350px", // Adjust the maximum width as needed
    padding: "20px", // Add padding as needed
    backgroundColor: "var(--lighter-color)",
    minHeight: "400px", // Adjust the maximum width as needed
    zIndex: "10000",
    maxWidth: "450px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "1000",
  },
};

const DownloadProspectus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useAuth();
// const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
  };

  const handleSubscribe = () => {
    if(isLoggedIn){
        const url = "https://assets.ctfassets.net/fxuhbm32k7qv/5xuEEOg2JFsqU8aGwfsvaf/e98955fda728078546fce44a975c1985/HFolds_Brochure_Folder_1.pdf";
        window.open(url, "_blank");
    }
    else{
        setIsOpen(true);
    }
  };

  const handleProspectus = () => {
    if(formData.name === '' || formData.email === '' || formData.phone === ''){
        }
    else{
        const url = "https://assets.ctfassets.net/fxuhbm32k7qv/5xuEEOg2JFsqU8aGwfsvaf/e98955fda728078546fce44a975c1985/HFolds_Brochure_Folder_1.pdf";
        window.open(url, "_blank");
    }
    
  }

  const handleSubmit = (e) => {
    e.preventDefault();
      if(formData.name !== '' && formData.email !== '' && formData.phone !== '')
    {const newFormData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
      };
      axios
        .post(`${BASE_USER_URL}/prospectus`, newFormData)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

    console.log(newFormData);
    
    setIsOpen(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
    });}
  };

  return (
    <>
      <button
            onClick={handleSubscribe}
            className={`tw-border tw-border-4 tw-border-double tw-text-xl tw-px-6
                        tw-py-3 tw-bg-secondary hover:tw-bg-transparent  hover:tw-border-accent hover:tw-text-primary tw-text-white tw-rounded-full tw-w-38 tw-transition-opacity `}
            >
            Download Prospectus
        </button>
      <Modal
        isOpen={(isOpen && !isLoggedIn)}
        onRequestClose={() => setIsOpen(false)}
        style={customModalStyles}
      >
        {!isLoggedIn && (
          <>
            <h2 className="tw-text-center tw-font-bold tw-mb-10 tw-text-3xl">
              Download your Prospectus 
            </h2>
            <form
              onSubmit={handleSubmit}
              className="tw-flex tw-flex-col tw-space-y-3 "
            >
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="tw-p-2 tw-border tw-border-2 tw-border-accent tw-rounded-lg"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="tw-p-2 tw-border tw-border-2 tw-border-accent tw-rounded-lg"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                className="tw-p-2 tw-border tw-border-2 tw-border-accent tw-rounded-lg"
              />
              
              <button
                type="submit"
                className="tw-border tw-border-2 tw-p-3 tw-w-2/3 tw-mx-auto tw-bg-secondary tw-text-white tw-rounded-xl "
                onClick={handleProspectus}
              >
                Download
              </button>
            </form>
          </>
        )}
      </Modal>
    </>
  );
};

export default DownloadProspectus;
