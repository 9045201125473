import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { CTooltip, CButton } from "@coreui/react";
import { useAuth } from "../../AuthContext";

const CustomToolTipButton = ({ content, placement, children }) => {
  return (
    <CTooltip content={content} placement={placement}>
      <CButton color="white" style={{ padding: "0px" }}>
        {children}
      </CButton>
    </CTooltip>
  );
};

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isLoggedIn, logout, userDetails } = useAuth();
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="tw-container tw-bg-lighest">
      <div
        className="tw-bg-lightest tw-border-primary tw-text-primary tw-fixed tw-w-full tw-top-0 tw-p-8 tw-px-4 md:tw-px-32 tw-flex tw-justify-between tw-items-center tw-z-10 tw-h-32"
        ref={menuRef}
      >
        {/* Responsive Menu Icon */}
        <div className="md:tw-hidden tw-cursor-pointer" onClick={toggleMenu}>
          <CiMenuBurger className="tw-w-6 tw-h-6 tw-text-primary" />
        </div>
        <div className="tw-flex tw-items-center">
          <img
            src="https://images.ctfassets.net/fxuhbm32k7qv/3AEHDcAIZqKsSXdZJS6RQo/45488d768f431f846ed226468dee089f/White_bg__Horizontal_logo__300_150.png"
            alt="100FoldsAcademy"
            className=" tw-ml-16 tw-mt-2 tw-cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        {/* Links on the Right */}
        <div className="tw-hidden md:tw-flex tw-space-x-8">
          {isLoggedIn && (
            <>
              
              <CustomToolTipButton
                content={
                  userDetails.firstName + " " + userDetails.lastName
                }
                placement="bottom"
              >
                <button
                  className="hover:tw-bg-accent tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                  
                >
                  Profile
                </button>
              </CustomToolTipButton>

              <div
                className="hover:tw-bg-accent tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                onClick={handleLogout}
              >
                Logout
              </div>
            </>
          )}
          {/* Login Icon */}
          {!isLoggedIn && (
            <>
              <div
                className="hover:tw-bg-accent tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                onClick={() => navigate("/login")}
              >
                Login
              </div>

              <div
                className="hover:tw-bg-accent tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                onClick={() => navigate("/register")}
              >
                SignUp
              </div>
            </>
          )}
          </div>
      </div>

      {!menuOpen && (
        <div
          className=" tw-mx-auto tw-bg-lightest  tw-border tw-text-darker tw-fixed tw-w-full tw-top-32 tw-p-2 tw-flex tw-justify-center tw-items-center tw-z-10"
          ref={dropdownRef}
        >
          <div className="tw-flex tw-space-x-32 ">
            <div
              className="hover:tw-text-primary tw-text-xl tw-rounded-3xl tw-cursor-pointer hover:tw-bg-accent tw-px-4 tw-py-2 "
              onClick={() => navigate("/admin/dashboard")}
            >
              Dashboard
            </div>
            <div
              className="hover:tw-text-primary tw-text-xl tw-rounded-3xl tw-cursor-pointer hover:tw-bg-accent tw-px-4 tw-py-2 "
              onClick={() => navigate("/admin/courseRegister")}
            >
              Course Enrollment
            </div>
            <div
              className="hover:tw-text-primary tw-text-xl tw-rounded-3xl tw-cursor-pointer hover:tw-bg-accent tw-px-4 tw-py-2 "
              onClick={() => navigate("/register")}
            >
              Student Registration
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminNavbar;
