// UserProfilePage.js
import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import { BASE_USER_URL } from "../../utils/APIURLs";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { getCookieValue } from "../../utils/manageCookie";
import "react-phone-number-input/style.css";
import axios from "axios";

const UserProfileDetails = () => {
  const { isLoggedIn, userDetails, fetchUserDetails } = useAuth();
  const [profileDetails, setProfileDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setProfileDetails({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phone: userDetails.phone,
        dob: userDetails.dob,
      });

      console.log(userDetails)
    }
  }, [userDetails]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleSave = () => {
    setEditMode(false);
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .put(`${BASE_USER_URL}/editUserProfile`, profileDetails, config)
      .then((res) => {
        const { firstName, lastName, email, phone, dob } = res.data;
        const dobString = dob.slice(0, 10);
        setProfileDetails({
          firstName,
          lastName,
          email,
          phone,
          dob: dobString,
        });
        toast.success("Profile updated successfully");
        fetchUserDetails(authToken);
      })
      .catch((error) => {
        toast.error("Failed to update profile");
        console.log(error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails({
      ...profileDetails,
      [name]: value,
    });
  };

  const handlePhone = (value) => {
    console.log(value);
    setProfileDetails((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  return (
    <div className="tw-bg-lightest tw-border tw-border-2 tw-border-accent tw-p-8 tw-rounded-lg tw-h-full ftw-lex tw-flex-col">
      <h1 className="tw-text-2xl tw-font-bold tw-mb-4 tw-mx-auto">
        User Profile
      </h1>
      <div className="tw-mb-4">
        <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
          First Name
        </label>
        <input
          type="text"
          name="firstName"
          value={profileDetails.firstName}
          onChange={handleInputChange}
          readOnly={!editMode}
          className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-w-full"
        />
      </div>
      <div className="tw-mb-4">
        <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
          Last Name
        </label>
        <input
          type="text"
          name="lastName"
          value={profileDetails.lastName}
          onChange={handleInputChange}
          readOnly={!editMode}
          className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-w-full"
        />
      </div>
      <div className="tw-mb-4">
        <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
          Email
        </label>
        <input
          type="email"
          name="email"
          value={profileDetails.email}
          onChange={handleInputChange}
          readOnly={!editMode}
          className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-w-full"
        />
      </div>
      <div className="tw-mb-4">
        <label className="tw-block tw-text-gray-700 tw-font-bold mb-2">
          Phone
        </label>
        <PhoneInput
          id="phone"
          name="phone"
          defaultCountry="IN"
          placeholder="Phone"
          value={profileDetails.phone}
          onChange={(phone) => handlePhone(phone)}
          className="tw-mt-1 tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-w-full"
        />
      </div>
      <div className="tw-mb-4">
        <label className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2">
          Date of Birth
        </label>
        <input
          type="date"
          name="dob"
          format="yyyy-mm-dd"
          value={profileDetails.dob}
          onChange={handleInputChange}
          readOnly={!editMode}
          className="p-2 border border-gray-300 rounded-md w-full"
        />
      </div>
      {!editMode ? (
        <button
          onClick={handleEdit}
          className="tw-bg-secondary hover:tw-bg-transparent tw-border tw-border-4 tw-border-double
                 hover:tw-border-secondary tw-text-white hover:tw-text-secondary tw-font-bold tw-py-2 tw-px-4 tw-rounded-lg"
        >
          Edit Profile
        </button>
      ) : (
        <div className="tw-flex tw-flex-row tw-w-full">
          <button
            onClick={handleCancel}
            className="tw-w-1/2 tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="tw-bg-green-500 tw-w-1/2 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default UserProfileDetails;
