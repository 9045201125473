import React from "react";
import MissionImage from "../../images/Mission.png";
import VisionImage from "../../images/Vision.png";
import AboutAcademyImage from "../../images/aboutAcademy.jpg";

const Academy = () => {
    return (
        <div className="tw-container tw-mx-auto tw-my-32">
            <div className="tw-flex tw-flex-col tw-items-center">
                <h1 className="tw-text-4xl tw-font-bold tw-mb-4 tw-text-center tw-items-center tw-text-primary">About the Academy</h1>
                <div className="tw-flex md:tw-flex-row tw-flex-col">
                    {/* <div className="tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-w-1/2 tw-m-4"> */}
                    <div className="tw-flex tw-justify-center tw-items-center md:tw-w-1/2 tw-m-4 ">
                        <img src={AboutAcademyImage} alt="academy" className="tw-w-full" />
                    </div>
                    <p className="tw-text-lg tw-text-gray-800 tw-mt-8 tw-p-4 tw-text-justify tw-text-balance md:tw-w-1/2">100 Folds is a premier pastry school that celebrates flavors,
                        techniques, and traditions from every corner of the world. We believe that the essence of pastry making lies
                        in the perfect balance between the science and the art of baking. This philosophy is woven into every lesson,
                        every course, and every interaction within our walls, ensuring that our students not only master the craft but
                        also evolve as innovative creators in their own right. Our faculty comprises award-winning pastry chefs, industry
                        innovators, and seasoned educators who have garnered international acclaim. With a treasure trove of experiences,
                        pioneering pastry research, our instructors are not just teachers but mentors, dedicated to nurturing the next generation
                        of pastry professionals. Their guidance is personalized, their knowledge is vast, and their commitment to your
                        success is unwavering. Whether you are stepping into the world of pastry for the first time or looking to elevate
                        your professional career, your journey towards excellence starts here. Welcome to a world where passion meets
                        pastry—welcome to 100 Folds.
                    </p>
                    {/* </div> */}
                </div>
                <div class="tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-mt-8 tw-gap-2 tw-p-2" id="our-vision-mission">
                <div class="tw-relative ">
                    <img src={VisionImage} alt="Our Vision" className="tw-w-full md:tw-h-full tw-h-[300px]" />
                    <div class="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-3">
                        <h1 class="tw-text-4xl tw-font-bold tw-mb-4 tw-text-center tw-text-primary">Our Vision</h1>
                        <p class="tw-text-xl tw-text-primary tw-text-justify tw-p-3">
                            To be the <span className="tw-text-3xl tw-font-bold tw-text-accent">global</span> centre of <span className="tw-text-3xl tw-font-bold tw-text-accent">excellence</span> that inspires <span className="tw-text-3xl tw-font-bold tw-text-accent">innovation</span>, <span className="tw-text-3xl tw-font-bold tw-text-accent">creativity</span>, <span className="tw-text-3xl tw-font-bold tw-text-accent">learning</span> and <span className="tw-text-3xl tw-font-bold tw-text-accent">nurturing</span> a community of <span className="tw-text-3xl tw-font-bold tw-text-accent">professionals</span> committed to <span className="tw-text-3xl tw-font-bold tw-text-accent">balance</span>.
                        </p>
                    </div>
                </div>

                    <div class="tw-relative ">
                        <img src={MissionImage} alt="Our Mission" className="tw-w-full md:tw-h-full tw-h-[620px] " />
                        <div class="tw-absolute md:tw-inset-0 tw-top-5 tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-3">
                            <h1 class="tw-text-4xl tw-font-bold tw-mb-4 tw-text-center tw-text-primary">Our Mission</h1>
                            <p class="tw-text-xl tw-text-primary tw-text-justify tw-p-5">To deliver an exemplary learning experience, we uphold our commitment
                                to excellence in nurturing every generation and stream of experts. We encourage a culture of creativity and innovation where ideas
                                flourish and are celebrated. Through robust industry engagement, we ensure our alumni are well-versed in professionalism and equipped
                                with practical, real-world experience. We embrace diversity and strive for inclusivity, reflecting the global tapestry of culinary
                                traditions in our teaching methods. We commit ourselves to enriching our community and adopting a global outlook that prepares our
                                students to become respected connoisseurs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Academy;
