import React, { useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { BASE_USER_URL } from "../utils/APIURLs";

const NewsLetterPreferences = ({ formData, handleChange }) => {
  // Array of preferences
  const preferences = [
    { value: "Blogs", label: "Blogs" },
    { value: "Recipes", label: "Recipes" },
    { value: "News", label: "News" },
    { value: "Events", label: "Events" },
  ];

  return (
    <div className="tw-mb-4">
      <label className=" tw-text-2xl tw-my-4 tw-text-center tw-block tw-text-md tw-font-bold tw-text-secondary">
        Preferences
      </label>
      <div className="tw-flex tw-flex-wrap">
        {preferences.map((preference) => (
          <label
            key={preference.value}
            className="tw-inline-flex tw-items-center tw-mt-1 tw-mr-4"
          >
            <input
              type="checkbox"
              name="newsletterPreference"
              value={preference.value}
              onChange={handleChange}
              className="custom-checkbox tw-border tw-border-2 tw-h-4 tw-w-8 tw-text-primary "
            />
            <span className="tw-ml-2 tw-text-primary tw-font-medium ">
              {preference.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

const customModalStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "30px",
    transform: "translate(-50%, -50%)",
    minWidth: "350px", // Adjust the maximum width as needed
    padding: "20px", // Add padding as needed
    backgroundColor: "var(--lighter-color)",
    minHeight: "400px", // Adjust the maximum width as needed
    zIndex: "10000",
    maxWidth: "450px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "1000",
  },
};

const Newsletter = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    newsletterPreference: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useAuth();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "newsletterPreference") {
      const updatedPreferences = checked
        ? [...formData.newsletterPreference, value]
        : formData.newsletterPreference.filter(
            (preference) => preference !== value,
          );
      setFormData((prevState) => ({
        ...prevState,
        newsletterPreference: updatedPreferences,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubscribe = () => {
    setIsOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      let newsletterSubscribed =
        formData.newsletterPreference.length > 0 ? true : false;
      console.log(formData.newsletterPreference);
      const newFormData = {
        newsletterSubscribed,
        newsletterPreference: formData.newsletterPreference,
      };
      const token = document.cookie.split("=")[1];
      axios
        .put(`${BASE_USER_URL}/newsletter`, newFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const newFormData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        newsletterPreference: formData.newsletterPreference,
      };
      axios
        .post(`${BASE_USER_URL}/subscribe`, newFormData)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setIsOpen(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      newsletterPreference: [],
    });
  };

  return (
    <>
      <button
        className="tw-border tw-border-4 tw-border-double tw-border-primary tw-text-primary tw-font-bold  tw-rounded-full tw-p-2"
        onClick={handleSubscribe}
      >
        Subscribe
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customModalStyles}
      >
        {isLoggedIn && (
          // Content for registered and logged in user
          <>
            <h2 className="tw-text-center tw-text-primary tw-font-bold tw-mb-10 ">
              Subscribe to Newsletter
            </h2>
            <div className="tw-flex tw-flex-col tw-space-y-3 ">
              <NewsLetterPreferences
                formData={formData}
                handleChange={handleChange}
              />
              <button
                type="submit"
                className="tw-border tw-border-2 tw-p-3 tw-w-2/3 tw-mx-auto tw-bg-secondary tw-text-white tw-rounded-xl tw-mt-8"
                onClick={handleSubmit}
              >
                Subscribe
              </button>
            </div>
          </>
        )}
        {!isLoggedIn && (
          <>
            <h2 className="tw-text-center tw-font-bold tw-mb-10 tw-text-3xl">
              Subscribe to Newsletter
            </h2>
            <form
              onSubmit={handleSubmit}
              className="tw-flex tw-flex-col tw-space-y-3 "
            >
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="tw-p-2 tw-border tw-border-2 tw-border-accent tw-rounded-lg"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="tw-p-2 tw-border tw-border-2 tw-border-accent tw-rounded-lg"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                className="tw-p-2 tw-border tw-border-2 tw-border-accent tw-rounded-lg"
              />
              <NewsLetterPreferences
                formData={formData}
                handleChange={handleChange}
              />
              {/* Add preference checkboxes */}
              <button
                type="submit"
                className="tw-border tw-border-2 tw-p-3 tw-w-2/3 tw-mx-auto tw-bg-secondary tw-text-white tw-rounded-xl "
                onClick={handleSubmit}
              >
                Subscribe
              </button>
            </form>
          </>
        )}
      </Modal>
    </>
  );
};

export default Newsletter;
