import React from "react";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="tw-container tw-mx-auto tw-pt-16">
      <h1 className="tw-font-bold tw-text-center tw-mb-8 tw-text-primary">
        Admin Dashboard
      </h1>

      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-x-4 tw-gap-y-8 tw-mx-10 tw-mb-8 ">
        <div
          className="tw-border tw-p-16 tw-rounded-md  tw-cursor-pointer tw-bg-lightest tw-shadow-xl"
          onClick={() => navigate("/admin/manageEntity")}
        >
          <h2 className="tw-font-bold tw-mb-4 tw-text-secondary">Manage Entities</h2>
          <p className="tw-text-primary tw-text-lg">
            Add, Edit or Delete a new Course, Demo Class, Recipe or Course
            Category
          </p>
        </div>
        <div
          className="tw-border tw-p-16 tw-rounded-md tw-shadow-xl tw-bg-lightest tw-cursor-pointer"
          onClick={() => navigate("/admin/scheduleEntity")}
        >
          <h2 className="tw-font-bold tw-mb-4 tw-text-secondary">Manage Schedules</h2>
          <p className="tw-text-primary tw-text-lg">Schedule a new Course, Demo Class</p>
        </div>
        <div
          className="tw-border tw-p-16 tw-rounded-md tw-shadow-2xl tw-bg-lightest  tw-cursor-pointer"
          onClick={() => navigate("/register")}
        >
          <h2 className="tw-font-bold tw-mb-4 tw-text-secondary">Add a Student</h2>
          <p className="tw-text-primary tw-text-lg">Add a new student to the database</p>
        </div>
        <div
          className="tw-border tw-p-16 tw-rounded-md tw-shadow-2xl tw-bg-lightest tw-cursor-pointer"
          onClick={() => navigate("/admin/viewEnrollmentDetails")}
        >
          <h2 className="tw-font-bold tw-mb-4 tw-text-secondary tw-text-left">
            View Enrollment Details
          </h2>
          <p className="tw-text-primary tw-text-lg">
            View all the items and their details
          </p>
        </div>
        <div
          className="tw-border tw-p-16 tw-rounded-md tw-shadow-2xl tw-bg-lightest tw-cursor-pointer"
          onClick={() => navigate("/admin/viewAllUsers")}
        >
          <h2 className="tw-font-bold tw-mb-4 tw-text-secondary">View All Users</h2>
          <p className="tw-text-primary tw-text-lg">View Users and their details</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
