import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_USER_URL, BASE_PAYMENT_URL } from "../../utils/APIURLs";
import { formattedDate } from "../../utils/helpfulFunctions";
import { getCookieValue } from "../../utils/manageCookie";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "10px",
    transform: "translate(-50%, -50%)",
    minWidth: "400px", // Adjust the maximum width as needed
    padding: "20px", // Add padding as needed
    backgroundColor: "var(--lighest-color)",
    minHeight: "400px", // Adjust the maximum width as needed
    zIndex: "10000",
    maxWidth: "400px",
  },
  overlay: {
    zIndex: "1000",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const ChooseSlot = ({
  isOpen,
  setIsOpen,
  timeSlots,
  buttonType,
  itemId,
  price,
  courseCategory,
}) => {
  const [selectedSlot, setSelectedSlot] = useState({});
  const { cartSize, updateCartSize, userDetails, fetchAllEntities, fetchBoughtItems } = useAuth();
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [isSlotSelected, setIsSlotSelected] = useState(false);

  const getUTCDateString = (date) => {
    return date.toISOString().split('T')[0];
  };
  
  // Get current date in UTC as a string (YYYY-MM-DD)
  const currentDateUTC = getUTCDateString(new Date());
  
  const availableTimeSlots = timeSlots.filter(item => {
    // Extract just the date part from the startDate
    const slotDateUTC = item.slot.startDate.split('T')[0];
    // Compare date strings
    return slotDateUTC >= currentDateUTC;
  });
  
  const handleNext = () => {
    if (!selectedSlot._id) {
      toast.error("Please select a slot to continue");
      return;
    }
    setIsSlotSelected(true);
  };



  const initiatePayment = async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.post(`${BASE_PAYMENT_URL}`, {
        email: userDetails.email,
        amount: paymentAmount,
      }, config);

      if (res.status === 200) {
        window.location.href = res.data;
      }
    } catch (error) {
      const errorMessage = "Failed to buy course";
      toast.error(errorMessage);
    }
  }


  const handleApplyNow = async (slotId) => {
    if (paymentAmount === 0) {
      toast.error("Please fill all the fields to continue");
      return;
    }
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    const buyItems = [];
    buyItems.push({ _id: itemId, slotId, itemType: "course", amount: paymentAmount });
    localStorage.setItem("buyItems", JSON.stringify(buyItems));
    try {
      const res = await axios.post(
        `${BASE_USER_URL}/buyEntity`,
        { items: buyItems , email: userDetails.email },
        config,
      );

      if (res.status === 200) {
        if (res.data.cartPresent) {
          updateCartSize(cartSize - 1);
        }
        initiatePayment();
        // handleSendConfirmation(itemId, slotId, userDetails.email);
      }
      setIsOpen(false);
    } catch (error) {
      const msgs = error.error || "Failed to buy course";
      toast.error(msgs);
    }
  };

  const handleCart = (slotId) => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    if (authToken) {
      axios
        .post(
          `${BASE_USER_URL}/addEntityToCart`,
          { itemId, slotId, itemType: "course" },
          config,
        )
        .then((res) => {
          if (res.status === 200) {
            updateCartSize(cartSize + 1);
            toast.success("Course added to cart successfully");
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response.data.message || "Failed to add course to cart";
          console.log(errorMessage);
          toast.error(errorMessage);
        });
    } else {
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const existingItemIndex = cartItems.findIndex(
        (item) => item.itemId === itemId && item.slotId === slotId,
      );
      if (existingItemIndex === -1) {
        cartItems.push({ itemId, slotId, itemType: "course" });
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        updateCartSize(cartSize + 1);
        toast.success("Course added to cart successfully");
      } else {
        toast.error("Course is already in your cart");
      }
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (courseCategory.title !== "Services") {
      setPaymentAmount(price);
    }
  }, [selectedSlot]);


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customModalStyles}
    >
      <h2 className="tw-text-center tw-text-secondary">Choose Slot</h2>
      <div className="tw-flex tw-flex-col ">
        {availableTimeSlots.length > 0 ? (
          availableTimeSlots.map((item) => (
            <div
              key={item._id}
              onClick={() => setSelectedSlot(item)}
              className={`tw-border tw-border-accent tw-mb-4 tw-shadow-xl tw-rounded-md tw-text-center tw-text-xl tw-p-2  
                ${selectedSlot._id === item._id && item.usersEnrolled.length !== item.totalSeats
                  ? "tw-bg-accent tw-text-primary tw-font-bold"
                  : ""
                } tw-cursor-pointer 
                ${item.usersEnrolled.length === item.totalSeats ? "tw-opacity-50" : ""}
              `}
            >
              <p>
                {formattedDate(item.slot.startDate)} - {formattedDate(item.slot.endDate)}
              </p>
              {item.usersEnrolled.length === item.totalSeats ? (
                <p className="tw-text-red-500">Course Full</p>
              ) : (
                <p className="">
                  Available Seats : {item.totalSeats - item.usersEnrolled.length}
                </p>
              )}
            </div>
          ))
        ) : (
          <p className="tw-text-center tw-text-red-500">No available time slots for this course.</p>
        )}
      </div>
      {selectedSlot._id &&
        selectedSlot.usersEnrolled.length !== selectedSlot.totalSeats &&
        buttonType === "cart" && (
          <div className="tw-flex tw-justify-center tw-mt-4">
            <button
              className="tw-bg-accent tw-border tw-border-4 tw-px-6 tw-text-xl tw-border-double tw-p-4 tw-rounded-full tw-mr-4 tw-text-primary hover:tw-bg-secondary"
              onClick={() => handleCart(selectedSlot._id)}
            >
              Confirm
            </button>
          </div>
        )}
      {!isSlotSelected &&
        selectedSlot._id &&
        selectedSlot.usersEnrolled.length !== selectedSlot.totalSeats &&
        buttonType === "apply" && (
          <div className="tw-flex tw-justify-center tw-mt-4">
            <button
              className="tw-bg-accent tw-border tw-border-4 tw-px-6 tw-text-xl tw-border-double tw-text-white tw-p-4 tw-rounded-full tw-mr-4 hover:tw-bg-primary "
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        )}
      {isSlotSelected && (
        <>
          <div className="tw-mb-4">
            <label
              htmlFor="paymentAmount"
              className="tw-block tw-text-md tw-font-medium tw-text-gray-600"
            >
              Amount Paid
            </label>
            <input
              type="number"
              id="paymentAmount"
              name="paymentAmount"
              value={paymentAmount}
              // onChange={(e) => setPaymentAmount(e.target.value)}
              className="tw-mt-1 tw-p-2 tw-border tw-rounded tw-w-full"
              readOnly
            ></input>
          </div>
          <div className="tw-flex tw-justify-center tw-mt-4">
            <button
              className="tw-bg-accent tw-border tw-border-4 tw-px-6 tw-text-xl tw-border-double tw-text-white tw-p-4 tw-rounded-full tw-mr-4 hover:tw-bg-primary "
              onClick={() => handleApplyNow(selectedSlot._id)}
            >
              Initiate Payment
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ChooseSlot;
