import { CPagination, CPaginationItem } from "@coreui/react";
import React from "react";

const PaginationComponent = ({
  itemsPerPage,
  totalItems,
  handlePageChange,
  currentPage,
}) => {
  return (
    <div className="custom-pagination">
      <CPagination align="center">
        <CPaginationItem
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          previous="true"
        >
          Prev
        </CPaginationItem>
        {Array.from(
          { length: Math.ceil(totalItems / itemsPerPage) },
          (_, i) => i + 1,
        ).map((page, index) => (
          <CPaginationItem
            key={index}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </CPaginationItem>
        ))}
        <CPaginationItem
          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
          onClick={() => handlePageChange(currentPage + 1)}
          next="true"
        >
          Next
        </CPaginationItem>
      </CPagination>
    </div>
  );
};

export default PaginationComponent;
