import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import CHEF_QUERY from "../utils/queries/chefsQuery";

const ChefProfiles = () => {
  const { loading, error, data } = useQuery(
    gql`
            query {
                ${CHEF_QUERY}
            }`,
  );
  const originalChefs = data?.chefProfilesCollection.items || [];
  const chefs = [...originalChefs];
  chefs.sort((a, b) => a.id - b.id);

  return (
    <>
      <div className="chef-profiles">
        <div className="tw-container tw-mx-auto tw-my-10 tw-py-10 tw-px-2  tw-rounded-lg md:tw-w-1/2">
          <h2 className="tw-text-4xl tw-text-primary tw-font-semibold tw-mb-4 tw-text-center">
            Our Faculty Chefs
          </h2>
          <div
            className={`tw-grid lg:tw-grid-cols-${chefs.length} tw-grid-cols-1 tw-gap-2`}
          >
            {chefs.map((chef) => (
              <div
                className="tw-flex tw-flex-col tw-items-center tw-transition tw-duration-500 tw-ease-in-out tw-transform hover:tw-scale-110"
                key={chef.id}
                data-aos="fade-up"
              >
                <Link to={`/chefs?selectedChefId=${chef.id}`} key={chef.id}>
                  <img
                    src={chef.mainImage.url}
                    alt={chef.name}
                    className="tw-h-96 tw-object-cover tw-object-center tw-rounded-lg "
                  />
                </Link>
                <h3 className="tw-text-xl tw-text-secondary tw-font-semibold tw-mb-2">
                  {chef.name}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChefProfiles;
