import React, { useState } from "react";
import richTextRender from "../../utils/richTextRender";

const Policies = ({ data }) => {
  const [selectedPolicy, setSelectedPolicy] = useState("Cancellation/Refunds");
  console.log(data);
  const handlePolicyChange = (policy) => {
    setSelectedPolicy(policy);
  };

  return (
    <div className="tw-container tw-mx-auto  tw-flex md:tw-flex-row tw-flex-col tw-mt-8">
      <div className="tw-w-full md:tw-w-1/3 md:tw-border-r ">
        <h2 className="tw-text-center tw-text-primary tw-text-3xl tw-font-bold tw-mb-4 md:tw-pt-16">
          Policies
        </h2>
        <ul>
          {data.map((item, index) => (
            <li
              key={index}
              className={`tw-cursor-pointer tw-font-lg tw-py-2 tw-px-4 ${selectedPolicy === item.name && "tw-text-secondary"}`}
              onClick={() => handlePolicyChange(item.name)}
            >
              <p className="tw-font-bold tw-text-lg">{item.name}</p>
              <hr className="tw-border tw-border-1" />
            </li>
          ))}
        </ul>
      </div>
      {/* Right Section */}
      <div className=" tw-w-full md:tw-w-2/3 tw-pl-4 tw-px-4 md:tw-pt-16 ">
        {data.map((item, index) => (
          <div
            key={index}
            style={{ display: selectedPolicy === item.name ? "block" : "none" }}
          >
            <h2 className="tw-text-2xl tw-font-bold tw-text-secondary md:tw-ml-6">{item.name}</h2>
            <div className="tw-mt-4 tw-text-lg tw-text-primary md:tw-ml-6">
              {richTextRender(item.policyContent)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Policies;
