const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: "20px",
  },
  th: {
    border: "2px solid var(--primary-color)",
    backgroundColor: "var(--accent-color)",
    color: "var(--primary-color)",
    textAlign: "left",
    padding: "8px",
  },
  td: {
    border: "1px solid var(--primary-color)",
    textAlign: "left",
    padding: "8px",
  },
};

export default styles;
